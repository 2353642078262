import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import productService from "../Services/ProductService";
import supplierService from "../../Supplier/Services/SupplierService";
import { enqueueSnackbar } from "notistack";
import companyService, { IUploadFile } from "../../Company/Services/CompanyService";
import receiveService from "../../Inventory/Services/ReceiveService";
import clearState from "../../../utils/resetState";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import packagingService, { IPackaging, IPrintPackingList } from "../Services/PackagingService";
import PackagingCreateView from "../Views/PackagingCreateView";
import loginService from "../../Login/Services/LoginService";
import wareshousesService from "../../Inventory/Services/WareshousesService";
import materialService from "../../Inventory/Services/MaterialsService";
import groupingService, { IGrouping } from "../../Inventory/Services/GroupingService";
import gradeService from "../Services/GradeService";
import capacityService from "../Services/CapacityService";
import invoiceService, { IInvoice } from "../Services/InvoiceService";

const PackagingCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [invoices, setInvoices] = useState<IInvoice[]>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      id && getOne(id),
      getProduct(),
      getSupplier(),
      getWarehouse(),
      checkPermissionActionButton(),
      getInvoice(),
      getGrade(),
      getCapacity(),
      getProvince(),
      getMainMaterials(),
      getSubMaterials(),
    ])
      .then(() => {
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });


    return () => {
      clearState(packagingService, ["packaging"]);
      clearState(packagingService, ["bakes"]);
      clearState(packagingService, ["randoms"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getOne = async (id: string) => {
    try {
      const res = await packagingService.getOne(id);
      if (res.order?.id) {
        await getBake(res.order?.id);
        await getRandom(res.order?.id);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const getAllOrder = async (invoiceNo?: string) => {
    try {
      await packagingService.getAllOrders(invoiceNo);
    } catch (error) {
      console.error("An error occurred while creating the product:", error);
    }
  };


  const getInvoice = async () => {
    try {
      setIsLoading(true);
      const result = await invoiceService.getAll();
      if (result) {
        setInvoices(result ?? [] as IInvoice[]);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูล", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const getProduct = async () => {
    try {
      await productService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    }
  };

  //#region คลัง
  const getProvince = async () => {
    try {
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const getDistrict = async (id?: number) => {
    if (!id) return;
    try {
      await companyService.getDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the district:", error);
    }
  };

  const getSubDistrict = async (id?: number) => {
    if (!id) return;
    try {
      await companyService.getSubDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the sub-district:", error);
    }
  };

  const getWarehouse = async () => {
    try {
      await packagingService.getWarehouse();
    } catch (error) {
      console.error("An error occurred while getting the warehouse:", error);
    }
  };
  //#endregion

  const getGrade = async () => {
    try {
      await packagingService.getGrade();
    } catch (error) {
      console.error("An error occurred while getting the grade:", error);
    }
  };

  const getGrouping = async (warehouseId: string, packaging?: string) => {
    try {
      const grouping = await packagingService.getGrouping(warehouseId, packaging || '');
      return grouping;
    } catch (error) {
      enqueueSnackbar("เกิดข้อผิดพลาดในการเรียกข้อมูล", { variant: "error" });
      console.error("An error occurred while creating the product:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const permission = getPermissionBySubMenu("process-packaging");
    setPermission(permission);
  };

  const getSupplier = async () => {
    try {
      await supplierService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    }
  };

  const getBake = async (order: string) => {
    try {
      await packagingService.getBake(order, id);
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    }
  };

  const getRandom = async (order: string) => {
    try {
      await packagingService.getRandom(order, id);
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    }
  };

  const getCapacity = async () => {
    try {
      await packagingService.getCapacity();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    }
  };
  const onCreate = async (data: IPackaging, uploadFile: IUploadFile) => {
    try {
      const res = await packagingService.create(data, uploadFile);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(id || '');
      navigate(`/process-packaging-edit/${res?.id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while creating the product:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const onUpdate = async (data: IPackaging, oldImage: IUploadFile[], uploadFile: IUploadFile[], deleteImages?: IUploadFile[]) => {
    try {
      const _uploadFile = oldImage === uploadFile ? undefined : uploadFile;
      delete data.file;
      const res = await packagingService.update(id || '', { ...data, }, _uploadFile);
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            const isPdf = file.name && file.name.toLowerCase().endsWith('.pdf');
            return receiveService.deleteImages({
              file: file,
            }, isPdf);
          });
          await Promise.all(deletePromises);
        }
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        if (data.isDelete) {
          navigate(`/process-packaging`);
        }
        else if (id) {
          getOne(id);
        }
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while updating the packaging:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await companyService.getDistrict(id);
          break;
        case "subDistrict": await companyService.getSubDistrict(id);
          break;
        case "pack": await packagingService.getOne(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "warehouse":
          response = await wareshousesService.create(data);
          getWarehouse();
          break;
        case "material":
          response = await materialService.create(data);
          getMainMaterials();
          getSubMaterials();
          break;
        case "grade":
          response = await gradeService.create(data);
          getGrade();
          break;
        case "capacity":
          response = await capacityService.create(data);
          getCapacity();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  //#region กลุ่มวัสดุ
  const onCreateMaterialGroup = async (warehouse: string, data: IGrouping) => {
    try {
      const res = await groupingService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        getGrouping(warehouse, undefined);
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const getMainMaterials = async () => {
    try {
      await groupingService.getMainMaterials();
    } catch (error) {
      console.error(error);
    }
  };

  const getSubMaterials = async () => {
    try {
      await groupingService.getSubMaterials();
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion


  return (
    <PackagingCreateView
      groupings={packagingService.groupingsData ?? []}
      order={packagingService.ordersData ?? []}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onClose={() => navigate(`/process-packaging`)}
      onFetchBake={getBake}
      onFetchRandom={getRandom}
      bake={packagingService.bakeData}
      random={packagingService.randomData}
      permission={permission ?? {} as IUserDetail}
      packaging={packagingService.packagingData ?? {} as IPackaging}
      mainMaterial={packagingService.mainMaterialsData ?? []}
      onFetchGrouping={getGrouping}
      grades={packagingService.gradesData ?? []}
      warehouses={packagingService.warehouseData ?? []}
      user={loginService.userLoginedData ?? {} as IUserDetail}
      isLoading={isLoading}
      capacities={packagingService.capacityData ?? []}
      provinceData={companyService.provinceData || []}
      districtData={companyService.districtData || []}
      subDistrictData={companyService.subDistrictData || []}
      onFetchDistrict={getDistrict}
      onFetchSubDistrict={getSubDistrict}
      onCreateDialog={onCreateDialog}
      onFetchData={onFetchData}
      // กลุ่มวัสดุ
      mainMaterialsData={groupingService.mainMaterialsData ?? []}
      subMaterialsData={groupingService.subMaterialsData ?? []}
      onCreateMaterialGroup={onCreateMaterialGroup}
      printPackingListData={packagingService.printPackingListData ?? {} as IPrintPackingList}
      invoices={invoices ?? [] as IInvoice[]}
      onGetOrders={getAllOrder}
    />
  );
});
export default PackagingCreateController;