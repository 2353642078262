import { FC, useState } from "react";
import { IRandomInspection, IRandomInspectionCriteria, IRandomInspections } from "../Services/RandomInspectionService";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "../../../components/TableDataList";
import { documentStatus } from "../../../constants/documentStatus";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";

interface RandomInspectionListViewProps {
  isLoading: boolean;
  randomInspectionsData: IRandomInspections;
  searchByCriteria: (criteria: IRandomInspectionCriteria) => void;
  permission: IUserDetail;
}

const RandomInspectionListView: FC<RandomInspectionListViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const documentStatusOptions = Object.values(documentStatus);
  const navigate = useNavigate();

  const { control, getValues } = useForm<IRandomInspectionCriteria>({
    defaultValues: {
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
      status: "all",
      docNo: "",
      order: "",
    }
  });




  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IRandomInspectionCriteria = {
      dateFrom: dayjs(getValues("dateFrom")).format("YYYY-MM-DD"),
      dateTo: dayjs(getValues("dateTo")).format("YYYY-MM-DD"),
      docNo: getValues("docNo"),
      status: getValues("status"),
      order: getValues("order"),
      page: pageNumber,
      limit: rowLimit ?? 10,
    };

    props.searchByCriteria(criteria);
  };

  const randomInspectionColumns = [
    { label: 'วันที่เอกสาร', render: (row: IRandomInspection) => row.docDate ? dayjs(row.docDate).format("DD/MM/YYYY") : "" },
    { label: 'Invoice No.', render: (row: IRandomInspection) => row.order?.invoiceNo?.invoiceNo || "" },
    { label: 'ชื่อโปรเจกต์', render: (row: IRandomInspection) => row.order?.projectName || "" },
    { label: 'เลขที่รับจากสวน', render: (row: IRandomInspection) => row.farm?.docNo },
    { label: 'เลขที่เอกสาร', render: (row: IRandomInspection) => row.docNo },
    { label: 'น้ำหนักจริง', render: (row: IRandomInspection) => row.actualWeight ? thaiFormatNumber(row.actualWeight.toString()) : "0" },
    { label: 'น้ำหนักสุทธิ', render: (row: IRandomInspection) => row.totalRemainingWeight ? thaiFormatNumber(row.totalRemainingWeight.toString()) : "0" },
    {
      label: 'บันทึกล่าสุด', render: (row: IRandomInspection) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IRandomInspection) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'สถานะ', render: (row: IRandomInspection) => <Chip
        label={documentStatus[row.status]?.name || 'default'}
        color={documentStatus[row.status]?.color as "default"}
        onClick={() => handleNavigateToCreate('status', row.id)}
      />
    },

  ];

  const handleNavigateToCreate = (from: string, id?: string) => {
    if (id) {
      navigate(`/process-random-inspection-create/${id}`, { state: { from } });
    } else {
      navigate(`/process-random-inspection-create`, { state: { from } });
    }
  };

  const randomInspectionActions = (row: IRandomInspection) => (
    <IconButton
      onClick={() => handleNavigateToCreate('arrow', row.id)}
    >
      <KeyboardArrowRightIcon />
    </IconButton >
  );

  const actionsButton = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => handleNavigateToCreate('create')}>
      สร้าง
    </Button>
  ) : <></>;



  return (
    <ListViewPage
      breadcrumb={
        <>  <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ตรวจสอบคุณภาพ</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>

        </>
      }

      criteria={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Controller
                name="dateFrom"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                      <DatePicker sx={{ width: "100%" }}
                        defaultValue={dayjs().startOf('month')}
                        label="ตั้งแต่วันที่"
                        value={field.value ? dayjs(field.value) : dayjs()}
                        onChange={(newValue: any) => {
                          field.onChange(newValue);
                          setDateRangeToStorage(STORAGE_KEYS.PROCESS, newValue.startOf('day').toDate(), dayjs(getValues('dateTo')) ?? dayjs().startOf('day'));
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Controller
                name="dateTo"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                      <DatePicker
                        sx={{ width: "100%" }}
                        defaultValue={dayjs()}
                        label="ถึงวันที่"
                        value={field.value ? dayjs(field.value) : dayjs()}
                        onChange={(newValue: any) => {
                          field.onChange(newValue);
                          setDateRangeToStorage(STORAGE_KEYS.PROCESS, dayjs(getValues('dateFrom')) ?? dayjs().startOf('month').startOf('day'), newValue.startOf('day').toDate());
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Controller
                name="order"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="ชื่อโปรเจกต์"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
              <Controller
                name="docNo"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="เลขที่เอกสาร"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">สถานะ</InputLabel>

                    <Select
                      labelId="status-select-label"
                      value={field.value || "all"}
                      onChange={(e) => field.onChange(e.target.value)}
                      label="สถานะ"
                    >                      {documentStatusOptions.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.name}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => searchByCriteria(0)}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }

      table={
        <>
          <TableDataList
            data={props.randomInspectionsData.datas}
            total={props.randomInspectionsData.total}
            columns={randomInspectionColumns}
            actionButtons={actionsButton}
            rowActions={randomInspectionActions}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              setPage(newPage);
              searchByCriteria(newPage, limit);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>
      }
      isLoading={props.isLoading ?? false}

    />
  );

};

export default RandomInspectionListView;