import { observer } from "mobx-react-lite";
import OrderService, { IOrderCriteria } from "../Services/OrderService";
import { useEffect, useState } from "react";

import productService, { IProductListView } from "../Services/ProductService";
import dayjs from "dayjs";
import OrderListView from "../Views/OrderListView";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import invoiceService, { IInvoice } from "../Services/InvoiceService";

const OrderListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);

  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState<IInvoice[]>();
  const defaultCriteria: IOrderCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    invoiceNo: '',
    transportType: 0,
    destination: '',
    product: '',
    projectName: '',
    status: 99,
    page: 0,
    limit: 10,
  };

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);
  useEffect(() => {
    fetchOrdersByCriteria(defaultCriteria);
    getProduct();
    getInvoice();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const fetchOrdersByCriteria = async (criteria: IOrderCriteria) => {
    try {
      setIsLoading(true);
      await OrderService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the   orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProduct = async () => {
    try {
      setIsLoading(true);
      await productService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInvoice = async () => {
    try {
      setIsLoading(true);
      const result = await invoiceService.getAll();
      if (result) {
        setInvoices(result ?? [] as IInvoice[]);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูล", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return < OrderListView
    isLoading={isLoading}
    Orders={OrderService.ordersData ?? {} as IProductListView}
    productSelects={productService.selectProduct ?? []}
    onSearchByCriteria={fetchOrdersByCriteria}
    invoices={invoices ?? [] as IInvoice[]}
  />;
});

export default OrderListController;