import { IOrderWithoutRandom } from "../../features/ProductProcess/Services/OrderService";
import { IUserLogined } from "../../features/Login/Services/LoginService";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import TableProcessSummaryRow from "../TableProcessSummaryRow";


interface ExportOrderProps {
  data: IOrderWithoutRandom;
  user?: IUserLogined;
}

const ExportOrder = React.forwardRef((props: ExportOrderProps, ref) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const farm = ['เอกสาร', 'สินค้า', 'Agent', 'จำนวนตะกร้า', 'kg(price/kg)', 'G Total', 'สุ่มผ่าก่อนชั่ง', 'lost M'];
  const vht = ['เอกสาร', 'out | censor | issue', 'lost M', 'ค่า VHT', 'VHT7%', 'VHT3%', 'Total'];
  const quality = ['เอกสาร', 'โรงอบ', 'น้ำหนัก', 'out | claim | rip', 'น้ำหนักขั้วที่ตัดทิ้ง', 'lost M', 'น้ำหนักหลังอบ'];
  const pack = ['เอกสาร', 'จำนวนทั้งหมด', 'จำนวนส่งออกทั้งหมด', 'ค่าบรรจุภัณฑ์'];
  const shipping = ['เอกสาร', 'ประเภท', 'จำนวน (กล่อง)', 'NonVat', 'Vat', 'Vat7%', 'Vat3%', 'Total Amount', 'Shipping Per Box'];

  const processes = [{ name: 'รับจากสวน', col: farm.length }, { name: 'ตรวจคุณภาพ', col: quality.length }, { name: 'อบ', col: vht.length }, { name: 'แพ็ค', col: pack.length }, { name: 'Shipping', col: shipping.length }, { name: 'ขายตลาด', col: 1 }];

  const eliminate = ['จำนวนเงิน'];

  const allColSpan = Number(quality.length) + Number(farm.length) + Number(vht.length) + Number(pack.length) + Number(shipping.length) - 1 + Number(eliminate.length);

  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      const handleResize = () => {
        const newWidth = element.offsetWidth;
        const _containerWidth = newWidth - 20;
        setContainerWidth(_containerWidth < 265 ? 265 : _containerWidth);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const commonStyles = (isBorderRight: boolean = false, head: boolean = false) => ({
    bgcolor: head ? '#efeded' : 'background.paper',
    border: 0.5,
    borderColor: 'lightgrey',
    textAlign: 'center',
    borderLeftColor: 'lightgrey',
    borderRight: isBorderRight ? 2 : 0.5,
    borderRightColor: 'lightgrey',
  });

  return (
    <Box ref={ref} sx={{ textAlign: 'center', padding: '20px', '&@page': { size: 'A4' }, }}>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2} ref={containerRef}>
          <TableContainer component={Paper} sx={{ maxWidth: `${containerWidth}px` }}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*NOTE: First Row: Main Headers */}
                  {processes.map((process, processeIndex) => (
                    <TableCell
                      key={processeIndex}
                      colSpan={process.col}
                      align="center"
                      sx={{ ...commonStyles(processeIndex !== processes.length - 1, true) }}
                    >
                      {process.name}
                    </TableCell>
                  ))}
                </TableRow>

                {/*NOTE: Second Row: Sub-headers */}
                <TableRow>
                  {/*NOTE: Group 1: รับจากสวน */}
                  {farm.map((text, textIndex) => (
                    <TableCell
                      key={`farm-${textIndex}`}
                      sx={commonStyles(textIndex === farm.length - 1)}
                    >
                      {text}
                    </TableCell>
                  ))}

                  {/*NOTE: Group 2: ตรวจคุณภาพ */}
                  {quality.map((text, textIndex) => (
                    <TableCell
                      key={`quality-${textIndex}`}
                      sx={commonStyles(textIndex === quality.length - 1)}
                    >
                      {text}
                    </TableCell>
                  ))}

                  {/*NOTE: Group 3: VHT */}
                  {vht.map((text, textIndex) => (
                    <TableCell
                      key={`vht-${textIndex}`}
                      sx={commonStyles(textIndex === vht.length - 1)}
                    >
                      {text}
                    </TableCell>
                  ))}

                  {/*NOTE: Group 4: pack */}
                  {pack.map((text, textIndex) => (
                    <TableCell
                      key={`pack-${textIndex}`}
                      sx={commonStyles(textIndex === pack.length - 1)}
                    >
                      {text}
                    </TableCell>
                  ))}

                  {/*NOTE: Group 5: Shipping */}
                  {shipping.map((text, textIndex) => (
                    <TableCell
                      key={`shipping-${textIndex}`}
                      sx={commonStyles(textIndex === shipping.length - 1)}
                    >
                      {text}
                    </TableCell>
                  ))}

                  {/*NOTE: Group 6: Eliminate */}
                  {eliminate.map((text, textIndex) => (
                    <TableCell
                      key={`eliminate-${textIndex}`}
                      sx={commonStyles()}
                    >
                      {text}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableProcessSummaryRow order={props?.data} vht={vht} quality={quality} pack={pack} shipping={shipping} user={props?.user} allColSpan={allColSpan} />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ExportOrder;