import { FC, useEffect, useState } from "react";
import { IDistrict, IProduct, IProvince, ISubDistrict } from "../Services/ProductService";
import { Controller, useForm } from "react-hook-form";
import { Box, Breadcrumbs, Button, ButtonProps, Grid, IconButton, Typography, Link, CardContent, Autocomplete, Card, Divider, TextField, Stack, FormControl, InputLabel, MenuItem, Select, createFilterOptions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import DialogConfirm from "../../../components/DialogConfirm";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { IOrder, IOrderCriteria, IOrderListView } from "../Services/OrderService";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "../../../components/TableDataList";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IUserDetail } from "../../Login/Services/LoginService";
import { statusOrderOptions } from "../../../constants/statusOrderOptions";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import { supplierDatas } from "../../../constants/dialogCreateInputs";
import DialogCreate from "../../../components/DialogCreate";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

interface ProductDetailViewProps {
  product?: IProduct;
  suppliers: ISupplier[];
  onUpdate: (data: IProduct) => void;
  onDelete: (data: IProduct) => void;
  getOrderByCriteria: (criteria: IOrderCriteria) => void;
  orders: IOrderListView;
  permission: IUserDetail;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
  supplierTypesOptions?: ISupplierType[];
}

const filterOptions = createFilterOptions<any>();
const ProductDetailView: FC<ProductDetailViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const { register, handleSubmit, reset, getValues, formState: { errors }, control, watch } = useForm<IProduct>();

  const { control: controlCriteria, getValues: getValuesCriteria, setValue: setValueCriteria } = useForm<IOrderCriteria>({
    defaultValues: {
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
      status: 99
    }
  });



  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchDateType, setSearchDateType] = useState("date");
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const productProcessStatusOptions = [
    { value: '99', label: 'ทั้งหมด' },
    ...Object.values(statusOrderOptions)
  ];

  //SECTION - Variables for  TableDataList
  const OrderColumns = [
    { label: 'วันที่สั่งซื้อ', render: (row: IOrder) => `${dayjs(row.dateFrom).format('DD/MM/YYYY')} - ${dayjs(row.dateTo).format('DD/MM/YYYY')}` },
    { label: 'Invoice No.', render: (row: IOrder) => row.invoiceNo?.invoiceNo ?? "" },
    { label: 'ชื่อโปรเจกต์', render: (row: IOrder) => row.projectName },
    {
      label: 'จำนวน(Kg)', render: (row: IOrder) => {
        const sum = row.orderDetails && row.orderDetails.length > 0 ? row.orderDetails.reduce((prev, current) => prev + (current.quantity || 0), 0) : 0;
        return parseFloat(sum.toString()).toFixed(2);
      }
    },
    { label: 'สถานะ', render: (row: IOrder) => row.isComplete ? "เสร็จสิ้น" : "รอดำเนินการ" },
  ];

  const navigate = useNavigate();

  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");

  // NOTE: supplier input
  const datasInChildCreateDialog = [{ type: "text", label: "ประเภท", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: "" },];
  const _supplierDatas: any = supplierDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    datasInChildCreateDialog: datasInChildCreateDialog || [],
    supplierTypesOptions: props?.supplierTypesOptions || [],
  }) || [];

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "supplier": setOpenCreateDailog(
        {
          open: true, datas: _supplierDatas, type: "supplier", title: "สร้าง Supplier"
        }
      );
        break;
    }
  };



  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
    reset();
  };

  const onFormSubmit = (data: IProduct) => {
    props.onUpdate(data);
    reset();
  };

  const onSubmit = (type: "create" | "delete") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit((data) => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      })();
    }
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ?? 10);
    const criteria: IOrderCriteria = {
      ...getValuesCriteria(),
      dateFrom: dayjs(getValuesCriteria().dateFrom).format("YYYY-MM-DD"),
      dateTo: dayjs(getValuesCriteria().dateTo).format("YYYY-MM-DD"),
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.getOrderByCriteria(criteria);
  };

  const orderAction = (row: IOrder) => (
    <IconButton
      onClick={() =>
        navigate(`/process-order-edit/${row.id}`)
      }
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  useEffect(() => {
    if (props.product) {
      reset(props.product);
      setValueCriteria("product", props.product.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.product, reset]);

  watch();

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.supplierTypesOptions]);



  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/process-product")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การจัดการวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/process-product")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.product && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component={"form"} onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      label="ชื่อผลิตภัณฑ์"
                      {...register("name", { required: true })}
                      InputLabelProps={
                        {
                          shrink: getValues("name") ? true : false
                        }
                      }
                      error={!!errors.name}
                      helperText={errors.name ? "กรุณาระบุชื่อผลิตภัณฑ์" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Controller
                      name="supplier"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value || null}
                          options={Array.isArray(props.suppliers) ? props.suppliers : []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("supplier");
                              onChange(null);
                            } else if (newValue?.id) { onChange(newValue); }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.supplier}
                            helperText={errors.supplier ? "กรุณาเลือก Supplier" : ""}
                            {...params} label="Supplier" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      label="รายละเอียด"
                      {...register("remark")}
                      InputLabelProps={
                        {
                          shrink: getValues("remark") ? true : false
                        }
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/process-product")}
                          >
                            ยกเลิก
                          </Button>
                          {
                            props.permission.edit &&
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={() => onSubmit("create")}
                              style={{ marginLeft: '8px' }}
                            >
                              บันทึก
                            </Button>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type === "delete" ? "error" : "success"}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                  onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === "delete") {
                      onDelete();
                    } else {
                      handleSubmit(onFormSubmit)();
                    }
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="h6" color="text.primary">ข้อมูลการสั่งซื้อผลิตภัณฑ์</Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={10} lg={10} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={3} lg={3} >
                      <FormControl fullWidth>
                        <InputLabel id="search-select-label">เลือกช่วงเวลา</InputLabel>
                        <Select
                          labelId="search-select-label"
                          id="search-select"
                          label="เลือกช่วงเวลา"
                          value={searchDateType}
                          onChange={(e) => setSearchDateType(e.target.value)}
                        >
                          <MenuItem value="all">ทั้งหมด</MenuItem>
                          <MenuItem value="date">วันที่ทำรายการ</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {searchDateType === "date" ? (
                      <>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                          <Controller
                            name="dateFrom"
                            control={controlCriteria}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                <DatePicker
                                  label="ตั้งแต่วันที่"
                                  sx={{ width: "100%" }}
                                  value={field.value ? dayjs(field.value) : dayjs()}
                                  onChange={(newValue: any) => {
                                    field.onChange(newValue);
                                    setDateRangeToStorage(STORAGE_KEYS.PROCESS, newValue.startOf('month').startOf('day').toDate(), dayjs(getValuesCriteria('dateTo')) ?? dayjs().startOf('day'));
                                  }}
                                />
                              </LocalizationProvider>
                            )}
                          />

                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                          <Controller
                            name="dateTo"
                            control={controlCriteria}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                <DatePicker
                                  label="ถึงวันที่"
                                  sx={{ width: "100%" }}
                                  value={field.value ? dayjs(field.value) : dayjs()}
                                  onChange={(newValue: any) => {
                                    field.onChange(newValue);
                                    setDateRangeToStorage(STORAGE_KEYS.PROCESS, dayjs(getValuesCriteria('dateFrom')) ?? dayjs().startOf('month').startOf('day'), newValue.startOf('day').toDate());
                                  }}
                                />
                              </LocalizationProvider>
                            )}
                          />

                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <Controller
                        name="status"
                        control={controlCriteria}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="status-select-label">สถานะ</InputLabel>
                            <Select
                              labelId="status-select-label"
                              id="status-select"
                              value={field.value}
                              label="สถานะ"
                              onChange={(event) => {
                                const newValue = productProcessStatusOptions?.find((item) => item.value === event.target.value);
                                field.onChange(newValue ? newValue.value : 0);
                              }}
                            >
                              {productProcessStatusOptions.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} textAlign="end" alignSelf="center">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} textAlign="end" alignSelf="center">
                      <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        onClick={() => searchByCriteria(0)}
                      >
                        ค้นหา
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TableDataList
                    data={props.orders?.datas || []}
                    total={props.orders?.total || 0}
                    columns={OrderColumns}
                    rowActions={orderAction}
                    page={page}
                    limit={limit}
                    onPageChange={(newPage) => {
                      setPage(newPage);
                      searchByCriteria(newPage, limit);
                    }}
                    onRowsPerPageChange={(newLimit) => {
                      setLimit(newLimit);
                      setPage(0);
                      searchByCriteria(0, newLimit);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })} onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
        props?.onFetchData(id, type);
      }} onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }} />
    </Box>
  );
};

export default ProductDetailView;