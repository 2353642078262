import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import reportService, { IReportCriteria, ISummaryReport, ISummaryReports } from "../Services/ReportService";
import dayjs from "dayjs";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { getDateRangeFromStorage, clearLocalStorageKeyOnUnload } from "../../../utils/dateRangeLocalStorage";
import SummaryReportListView from "../Views/SummaryReportListView";


const SummaryReportListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.REPORT);
  const [isLoading, setIsLoading] = useState(false);
  const [summaryReportData, setSummaryReportData] = useState<ISummaryReports>();
  const defaultCriteria: IReportCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getSummaryReportData(defaultCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.REPORT);

  const getSummaryReportData = async (criteria: IReportCriteria) => {
    try {
      setIsLoading(true);
      await reportService.getSummaryReports(criteria).then((data) => {
        data?.datas?.sort((a: ISummaryReport, b: ISummaryReport) => (b.orderDate?.toString() ?? '').localeCompare(a.orderDate?.toString() ?? ''));
        setSummaryReportData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the loss reports:", error);
    } finally {
      setIsLoading(false);
    }
  };




  return <SummaryReportListView isLoading={isLoading} summaryReportData={summaryReportData ?? {} as ISummaryReports}
    getSummaryReportData={getSummaryReportData}
  />;
});

export default SummaryReportListController;
