
import { FC, useEffect, useRef, useState } from "react";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, Button, ButtonProps, Card, CardContent, TextField, FormControl, Divider, useMediaQuery, Stack, Autocomplete, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputLabel, MenuItem, Select, createFilterOptions } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IDistrict, IproductSelect, IProvince, ISubDistrict } from "../Services/ProductService";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "../../../themes";
import DialogConfirm from "../../../components/DialogConfirm";
import { IOrderEstimatePrice, IOrderWithoutRandom, ITotalQuantitiesByGrade } from "../Services/OrderService";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import { IGrade } from "../Services/GradeService";
import TableProcessSummaryRow from "../../../components/TableProcessSummaryRow";
import { IUserLogined } from "../../Login/Services/LoginService";
import { statusOrderOptions } from "../../../constants/statusOrderOptions";
import ProductCreateView from "./ProductCreateView";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import GradeCreateView from "./GradeCreateView";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import OrderEstimatePriceCreateView from "./OrderEstimatePriceCreateView";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import DownloadIcon from '@mui/icons-material/Download';
import ExportOrder from "../../../components/Excel/ExportOrder";
import { IInvoice } from "../Services/InvoiceService";

interface OrderCreateViewProps {
  Order: IOrderWithoutRandom;
  productSelects: IproductSelect[];
  suppliers: ISupplier[];
  onCreate: (data: IOrderWithoutRandom) => void;
  onUpdate: (data: IOrderWithoutRandom) => void;
  onDelete: () => void;
  isLoading: boolean;
  grades: IGrade[];
  user?: IUserLogined;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
  supplierTypesOptions?: ISupplierType[];
  permission?: IUserDetail;
  onSubmitOrderEstimatePrice: (data: IOrderEstimatePrice) => void;
  invoices: IInvoice[];
}
const filterOptions = createFilterOptions<any>();
const defaultValue: IOrderWithoutRandom = {
  status: 0,
  dateFrom: dayjs().startOf('day'),
  dateTo: dayjs().startOf('day'),
  wage: 0,
  price: 0,
  orderDetails: [{
    id: uuidv4(),
    quantity: 0
  }],
};



const OrderCreateView: FC<OrderCreateViewProps> = (props) => {
  const { id } = useParams();
  const { register, handleSubmit, setValue, reset, getValues, formState: { errors }, control, watch } = useForm<IOrderWithoutRandom>({
    defaultValues: defaultValue
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "orderDetails"
  });
  const userGroup = props.user?.userGroup;
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === 'status';

  const isDisable = isFromStatus ? true : false;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [openOrderEstimatePriceDialog, setOpenOrderEstimatePriceDialog] = useState(false);

  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const farm = ['เอกสาร', 'สินค้า', 'Agent', 'จำนวนตะกร้า', 'kg(price/kg)', 'G Total', 'สุ่มผ่าก่อนชั่ง', 'lost M'];
  const vht = ['เอกสาร', 'out | censor | issue', 'lost M', 'ค่า VHT', 'VHT7%', 'VHT3%', 'Total'];
  const quality = ['เอกสาร', 'โรงอบ', 'น้ำหนัก', 'out | claim | rip', 'น้ำหนักขั้วที่ตัดทิ้ง', 'lost M', 'น้ำหนักหลังอบ'];
  const pack = ['เอกสาร', 'จำนวนทั้งหมด', 'จำนวนส่งออกทั้งหมด', 'ค่าบรรจุภัณฑ์'];
  const shipping = ['เอกสาร', 'ประเภท', 'จำนวน (กล่อง)', 'NonVat', 'Vat', 'Vat7%', 'Vat3%', 'Total Amount', 'Shipping Per Box'];

  const processes = [{ name: 'รับจากสวน', col: farm.length }, { name: 'ตรวจคุณภาพ', col: quality.length }, { name: 'อบ', col: vht.length }, { name: 'แพ็ค', col: pack.length }, { name: 'Shipping', col: shipping.length }, { name: 'ขายตลาด', col: 1 }];

  const eliminate = ['จำนวนเงิน'];
  const allColSpan = Number(quality.length) + Number(farm.length) + Number(vht.length) + Number(pack.length) + Number(shipping.length) - 1 + Number(eliminate.length);
  const [name, setName] = useState("");
  const exportRef = useRef(null);

  const commonStyles = (isBorderRight: boolean = false, head: boolean = false) => ({
    bgcolor: head ? '#efeded' : 'background.paper',
    border: 0.5,
    borderColor: 'lightgrey',
    textAlign: 'center',
    borderLeftColor: 'lightgrey',
    borderRight: isBorderRight ? 2 : 0.5,
    borderRightColor: 'lightgrey',
  });
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  watch();

  const handleSave = handleSubmit(() => {
    if (props.Order.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "update",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onSubmit = () => {
    setNameType("");
    const value = getValues();
    if (props.Order.id) {
      props.onUpdate(value);
    } else {
      value.status = 0;
      props.onCreate(value);
    }
  };

  const handleDelete = () => {
    setOpenConfirm({
      open: true,
      title: "ลบข้อมูล",
      message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
      color: "error",
      type: "delete",
    });
  };

  const onDelete = () => {
    props.onDelete();
  };

  const renderRows = () => {
    const rows: any = [];
    props.Order?.totalQuantitiesByGrade?.forEach((grade: ITotalQuantitiesByGrade, index: number) => {
      rows.push(
        <TableRow key={`grade-${grade?.gradeId}-index-${index}`}>
          <TableCell sx={commonStyles()}>{`${grade?.gradeName || ''} (${grade?.value || ''})`}</TableCell>
          <TableCell sx={commonStyles()}>{grade?.totalQuantity}</TableCell>
        </TableRow>
      );
    });

    return rows;
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "product": setOpenCreateDailog(
        {
          open: true, datas: [], type: "product", title: "สร้างผลิตภัณฑ์"
        }
      );
        break;
      case "grade": setOpenCreateDailog(
        {
          open: true, datas: [], type: "grade", title: "สร้างเกรด"
        }
      );
        break;
    }
  };

  const handleOpenOrderEstimatePriceDialog = () => {
    setOpenOrderEstimatePriceDialog(true);
  };

  const handleCloseOrderEstimatePriceDialog = () => {
    setOpenOrderEstimatePriceDialog(false);
  };

  const handleEstimateData = (data: IOrderEstimatePrice) => {
    setValue("orderEstimatePrice", data);
  };

  useEffect(() => {
    if (props.Order.id) {
      reset(props.Order);
      setValue("product", props.Order.product);
      setValue("orderDetails", props.Order.orderDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Order]);

  //NOTE - Resize table container width, Incomplete responsive
  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      const handleResize = () => {
        const newWidth = element.offsetWidth;
        const _containerWidth = newWidth - 20;
        setContainerWidth(_containerWidth < 265 ? 265 : _containerWidth);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.supplierTypesOptions, props.supplierTypesOptions]);
  return (
    <Box component="div" sx={{ width: '100%' }}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-order")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>โปรเจกต์</Typography>
            <Link component="button" onClick={() => navigate("/process-order")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.Order.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {props.Order.id && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleDelete()}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Typography variant="h6">โปรเจกต์</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name="dateFrom"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                error: !!errors.dateFrom,
                                helperText: errors.dateFrom ? "กรุณาระบุวันที่เริ่มต้น" : "",
                              }
                            }}
                            label="วันที่เริ่มต้น"
                            format="DD/MM/YYYY"
                            {...field}
                            defaultValue={dayjs(new Date())}
                            value={field.value ? dayjs(field.value) : dayjs(new Date())}
                            onChange={(date) => {
                              field.onChange(date ? date.format('YYYY-MM-DD') : null);
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name="dateTo"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                error: !!errors.dateTo,
                                helperText: errors.dateTo ? "กรุณาระบุวันที่สิ้นสุด" : "",
                              }
                            }}
                            label="วันที่สิ้นสุด"
                            format="DD/MM/YYYY"
                            {...field}
                            defaultValue={dayjs(new Date())}
                            value={field.value ? dayjs(field.value) : dayjs(new Date())}
                            onChange={(date) => {
                              field.onChange(date ? date.format('YYYY-MM-DD') : null);
                            }}

                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Controller
                    name="invoiceNo"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field.value as IInvoice || null}
                        options={props.invoices ?? []}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.invoiceNo || null;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            field.onChange(newValue);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Invoice No."
                            error={!!errors.invoiceNo}
                            helperText={errors.invoiceNo ? "กรุณาเลือก Invoice" : ""}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label="ชื่อโปรเจกต์"
                    {...register("projectName", { required: true })}
                    error={!!errors.projectName}
                    helperText={errors.projectName ? "กรุณาระบุชื่อโปรเจกต์" : ""}
                    InputLabelProps={{ shrink: getValues("projectName") ? true : undefined }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    fullWidth
                    label="ชื่อลูกค้า"
                    {...register("name", { required: true })}
                    error={!!errors.name}
                    helperText={errors.name ? "กรุณาระบุชื่อลูกค้า" : ""}
                    InputLabelProps={{ shrink: getValues("name") ? true : undefined }}
                  />

                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Controller
                    name="product"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        value={value as IproductSelect || null}
                        options={props.productSelects}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name || null;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(_, newValue) => {
                          if (newValue?.id === "create") {
                            handleOpenCreateDailog("product");
                            onChange(null);
                          } else if (newValue?.id) {
                            onChange(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filterOptions(options, params);
                          if (params.inputValue !== '') {
                            filtered.push({
                              name: `สร้าง '${params.inputValue}'`,
                              id: 'create',
                            });
                            setName(params.inputValue);
                          }
                          return filtered;
                        }}
                        renderOption={(props, option) => {
                          const { key, ...rest } = props;
                          return (
                            <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                              <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                            </Box> : option.name}</Typography>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="สินค้า"
                            error={!!errors.product}
                            helperText={errors.product ? "กรุณาเลือกสินค้า" : ""}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                {(userGroup?.canCost || userGroup?.canFinance) && (<>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                      fullWidth
                      type="number"
                      label="ราคาขาย"
                      {...register("price", { required: false })}
                      error={!!errors.wage}
                      helperText={errors.wage ? "กรุณาระบุราคาขาย" : ""}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </>)}
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Controller
                    name="isComplete"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="status-select-label">สถานะ</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={field.value?.toString() || "false"}
                          label="สถานะ"
                          onChange={(event) => {
                            field.onChange(event.target.value === "true");
                          }}
                        >
                          {statusOrderOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                {(userGroup?.canCost || userGroup?.canFinance) && <Grid item xs={12} mt={2} >
                  <Typography variant="h6" mb={1}>ประเมินราคาต้นทุน</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box
                        sx={{
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          padding: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid container spacing={3} justifyContent={"space-between"}>
                          <Grid item xs={12} sm={3} md={3} lg={3} >
                            <Typography variant="body1">
                              <span style={{ fontWeight: 'bold' }}>ต้นทุนต่อกล่อง:</span>
                              {getValues('orderEstimatePrice.totalCostPerBox') ? thaiFormatNumber(`${getValues('orderEstimatePrice.totalCostPerBox')}`) : 0}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}>
                            <Typography variant="body1">
                              <span style={{ fontWeight: 'bold' }}>ต้นทุน Shipping ต่อกล่อง:</span>
                              {getValues('orderEstimatePrice.shippingCostPerBox') ? thaiFormatNumber(`${getValues('orderEstimatePrice.shippingCostPerBox')}`) : 0}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3} md={3} lg={3}>
                            <Typography variant="body1">
                              <span style={{ fontWeight: 'bold' }}>ต้นทุนทั้งหมด:</span>
                              {getValues('orderEstimatePrice.totalCost') ? thaiFormatNumber(`${getValues('orderEstimatePrice.totalCost')}`) : 0}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2} md={2} lg={2} alignContent={"center"} justifyContent={"right"} display={"flex"}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleOpenOrderEstimatePriceDialog();
                              }}
                            >
                              ประเมินราคา
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>}

                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Typography variant="subtitle1">รายการสินค้า</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    {
                      fields.length > 0 && fields.map((item, index) => (
                        <React.Fragment key={item.id}>
                          {!isMobile && (
                            <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                              <IconButton
                                color="error"
                                onClick={() => remove(index)}
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={12} md={1} lg={1} justifyContent={'start'} alignItems={'center'} display={'flex'}>
                            {isMobile && "รายการที่ "}{index + 1}
                          </Grid>
                          <Grid item xs={6} sm={6} md={5} lg={5} textAlign={'center'}>
                            <Controller
                              name={`orderDetails.${index}.grade`}
                              control={control}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disabled={isDisable}
                                  value={value as IGrade || null}
                                  options={props.grades ?? []}
                                  getOptionLabel={(option: any) => {
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    return option.name || null;
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={(_, newValue) => {
                                    if (newValue?.id === "create") {
                                      handleOpenCreateDailog("grade");
                                      onChange(null);
                                    } else if (newValue?.id) {
                                      onChange(newValue);
                                    }
                                  }}
                                  clearOnBlur
                                  filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params);
                                    if (params.inputValue !== '') {
                                      filtered.push({
                                        name: `สร้าง '${params.inputValue}'`,
                                        id: 'create',
                                      });
                                      setName(params.inputValue);
                                    }
                                    return filtered;
                                  }}
                                  renderOption={(props, option) => {
                                    const { key, ...rest } = props;
                                    return (
                                      <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                        <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                      </Box> : option.name}</Typography>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="เกรด"
                                      error={!!errors.orderDetails?.[index]?.grade}
                                      helperText={errors.orderDetails?.[index]?.grade ? "กรุณาเลือกเกรด" : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={5} lg={5} textAlign={'center'}>
                            <Controller
                              name={`orderDetails.${index}.quantity`}
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  type="number"
                                  label="จำนวน (Kg)"
                                  {...field}
                                  error={!!errors.orderDetails?.[index]?.quantity}
                                  helperText={errors.orderDetails?.[index]?.quantity ? "กรุณาระบุจำนวน" : ""}
                                  InputLabelProps={{ shrink: field.value ? true : undefined }}
                                  onChange={(e) => {
                                    field.onChange(parseInt(e.target.value));
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {isMobile && index !== (fields.length - 1) && <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                            <Divider />
                          </Grid>}
                        </React.Fragment>
                      ))
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Grid container spacing={2}>
                        {isMobile ? <>
                          <Grid item xs={4} sm={4} >
                            <Button
                              variant="outlined"
                              color="error"
                              sx={{ width: '100%' }}
                              type="button"
                              startIcon={<RemoveCircleIcon />}
                              onClick={() => remove(fields.length - 1)}
                            >
                              ลบ
                            </Button>
                          </Grid>
                          <Grid item xs={8} sm={8} >
                            <Button
                              variant="outlined"
                              sx={{ width: '100%' }}
                              type="button"
                              startIcon={<AddCircleIcon />}
                              onClick={() => append({
                                id: uuidv4(),
                              })}
                            >
                              เพิ่มรายการ
                            </Button>
                          </Grid></> : <Grid item md={12} lg={12}>
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            type="button"
                            startIcon={<AddCircleIcon />}
                            onClick={() => append({
                              id: uuidv4(),
                            })}
                          >
                            เพิ่มรายการ
                          </Button>
                        </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate("/process-order")}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleSave}
                    >
                      บันทึก
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {id && <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Typography variant="h6">Size สินค้า</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={commonStyles(false, true)}
                          >
                            Size
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={commonStyles(false, true)}
                          >
                            จำนวน (กล่อง)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{renderRows()}</TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>}
      {id && <Grid container mt={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10} >
                  <Typography variant="h6">กระบวนการผลิต</Typography>
                </Grid>
                {
                  props.Order.id && (
                    <Grid item xs={2} sm={2} md={2} lg={2} display={"flex"} justifyContent={"end"}>
                      <DownloadTableExcel
                        filename={`${props.Order?.invoiceNo?.invoiceNo ?? ''}`}
                        sheet={`${props.Order?.invoiceNo}`}
                        currentTableRef={exportRef.current}
                      >
                        <Button component="label" variant="outlined" startIcon={<DownloadIcon />}>
                          Export Excel
                        </Button>
                      </DownloadTableExcel>
                    </Grid>
                  )
                }

                {/*NOTE: Table Section */}
                <Grid item xs={12} mt={2} ref={containerRef}>
                  <TableContainer component={Paper} sx={{ maxWidth: `${containerWidth}px` }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {/*NOTE: First Row: Main Headers */}
                          {processes.map((process, processeIndex) => (
                            <TableCell
                              key={processeIndex}
                              colSpan={process.col}
                              align="center"
                              sx={{ ...commonStyles(processeIndex !== processes.length - 1, true) }}
                            >
                              {process.name}
                            </TableCell>
                          ))}
                        </TableRow>

                        {/*NOTE: Second Row: Sub-headers */}
                        <TableRow>
                          {/*NOTE: Group 1: รับจากสวน */}
                          {farm.map((text, textIndex) => (
                            <TableCell
                              key={`farm-${textIndex}`}
                              sx={commonStyles(textIndex === farm.length - 1)}
                            >
                              {text}
                            </TableCell>
                          ))}

                          {/*NOTE: Group 2: ตรวจคุณภาพ */}
                          {quality.map((text, textIndex) => (
                            <TableCell
                              key={`quality-${textIndex}`}
                              sx={commonStyles(textIndex === quality.length - 1)}
                            >
                              {text}
                            </TableCell>
                          ))}

                          {/*NOTE: Group 3: VHT */}
                          {vht.map((text, textIndex) => (
                            <TableCell
                              key={`vht-${textIndex}`}
                              sx={commonStyles(textIndex === vht.length - 1)}
                            >
                              {text}
                            </TableCell>
                          ))}

                          {/*NOTE: Group 4: pack */}
                          {pack.map((text, textIndex) => (
                            <TableCell
                              key={`pack-${textIndex}`}
                              sx={commonStyles(textIndex === pack.length - 1)}
                            >
                              {text}
                            </TableCell>
                          ))}

                          {/*NOTE: Group 5: Shipping */}
                          {shipping.map((text, textIndex) => (
                            <TableCell
                              key={`shipping-${textIndex}`}
                              sx={commonStyles(textIndex === shipping.length - 1)}
                            >
                              {text}
                            </TableCell>
                          ))}

                          {/*NOTE: Group 6: Eliminate */}
                          {eliminate.map((text, textIndex) => (
                            <TableCell
                              key={`eliminate-${textIndex}`}
                              sx={commonStyles()}
                            >
                              {text}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableProcessSummaryRow order={props?.Order} vht={vht} quality={quality} pack={pack} shipping={shipping} user={props?.user} allColSpan={allColSpan} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>}
      <ProductCreateView open={openCreateDailog?.open && openCreateDailog?.type === "product"} onClose={() => {
        setOpenCreateDailog({ ...openCreateDailog, open: false });
        setName("");
      }} onCreate={(data) => props.onCreateDialog(data, nameType)} suppliers={props.suppliers ?? [] as ISupplier[]} provinceData={props.provinceData ?? []} districtData={props.districtData ?? []} subDistrictData={props.subDistrictData ?? []} supplierTypesOptions={props.supplierTypesOptions ?? []} onFetchData={props.onFetchData} onCreateDialog={props.onCreateDialog} name={name} />

      <GradeCreateView open={openCreateDailog?.open && openCreateDailog?.type === "grade"} onClose={() => {
        setOpenCreateDailog({ ...openCreateDailog, open: false });
        setName("");
      }} onSubmit={(data) => props.onCreateDialog(data, nameType)} permission={props.permission} name={name} />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onSubmit();
          }
        }}
      />
      <Box style={{ display: "none" }}>
        {<ExportOrder ref={exportRef} data={props.Order} user={props.user} />}
      </Box>

      <OrderEstimatePriceCreateView open={openOrderEstimatePriceDialog} onClose={handleCloseOrderEstimatePriceDialog} isLoading={props.isLoading} order={getValues() ?? [] as IOrderWithoutRandom} onSubmit={handleEstimateData} />
    </Box >
  );
};

export default OrderCreateView;
