import { Dayjs } from "dayjs";
import { IUser } from "../../UserManagement/Services/RoleService";
import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";

export interface IInvoice {
  id?: string;
  invoiceNo?: string;
  isComplete?: boolean;
  isDelete?: boolean;
  remark?: string;
  price?: number;
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
}

export interface IInvoiceCriteria {
  dateFrom?: string;
  dateTo?: string;
  invoiceNo?: string;
  isComplete?: boolean | number;
  page?: number;
  limit?: number;
}

export interface IInvoiceListView {
  datas?: IInvoice[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

class InvoiceService {
  invoices: IInvoiceListView | undefined;
  invoice: IInvoice | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const result = await apiService.get('invoices');
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async getOne(id: string) {
    try {
      this.invoice = await apiService.get(`invoices/${id}`);
      return this.invoice;
    } catch (error) {
      console.error(error);
    }
  }

  async getByCriteria(criteria: IInvoiceCriteria) {
    try {
      this.invoices = await apiService.post("invoices/criteria", criteria);
      return this.invoices;
    } catch (error) {
      console.error(error);
    }
  }

  async create(data: IInvoice) {
    try {
      const invoice = await apiService.post("invoices", data);
      return invoice;
    } catch (error) {
      console.error(error);
    }
  }

  async update(id: string, data: IInvoice) {
    try {
      const invoice = await apiService.put(`invoices/${id}`, data);
      return invoice;
    } catch (error) {
      console.error(error);
    }
  }

  async delete(id: string) {
    try {
      await apiService.delete(`invoices/${id}`);
    } catch (error) {
      console.error(error);
    }
  }

  get invoiceData() {
    return this.invoice;
  }

  get invoicesData() {
    return this.invoices;
  }
}

const invoiceService = new InvoiceService();
export default invoiceService;