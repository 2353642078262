import { observer } from "mobx-react-lite";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import invoiceService, { IInvoiceCriteria, IInvoiceListView } from "../Services/InvoiceService";
import InvoiceListView from "../Views/InvoiceListView";

const InvoiceListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const [isLoading, setIsLoading] = useState(false);
  const defaultCriteria: IInvoiceCriteria = {
    dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
    dateTo: initialDateRange.dateTo || dayjs().toDate(),
    invoiceNo: '',
    isComplete: 99,
    page: 0,
    limit: 10,
  };

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);
  useEffect(() => {
    fetchByCriteria(defaultCriteria);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const fetchByCriteria = async (criteria: IInvoiceCriteria) => {
    try {
      setIsLoading(true);
      await invoiceService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the   orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return <InvoiceListView
    isLoading={isLoading}
    invoices={invoiceService.invoicesData ?? {} as IInvoiceListView}
    onSearchByCriteria={fetchByCriteria}
  />;
});

export default InvoiceListController;