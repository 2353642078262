import { FC } from 'react';
import { TableRow, TableCell, Link, Box } from '@mui/material';
import { IOrderWithoutRandom } from '../features/ProductProcess/Services/OrderService';
import { useNavigate } from 'react-router-dom';
import { IFarmProductReceipt, IFarmProductReceiptWithcostPerBox } from '../features/ProductProcess/Services/FarmProductReceipt';
import { IPackagingDetail } from '../features/ProductProcess/Services/PackagingService';
import { IRandomInspection } from '../features/ProductProcess/Services/RandomInspectionService';
import { IBakeDetail } from '../features/ProductProcess/Services/BakeService';
import { IShippingDetail } from '../features/ProductProcess/Services/ShippingService';
import { IUserLogined } from '../features/Login/Services/LoginService';
import { documentStatus } from '../constants/documentStatus';

interface TableProcessSummaryRowProps {
  order: IOrderWithoutRandom;
  vht: string[];
  quality: string[];
  pack: string[];
  shipping: string[];
  user?: IUserLogined;
  allColSpan: number;
}

const commonStyles = (isBorderRight: boolean = false, head: boolean = false) => ({
  bgcolor: head ? '#efeded' : 'background.paper',
  border: 0.5,
  borderColor: 'lightgrey',
  textAlign: 'center',
  borderLeftColor: 'lightgrey',
  borderRight: isBorderRight ? 2 : 0.5,
  borderRightColor: 'lightgrey',
});

const TableProcessSummaryRow: FC<TableProcessSummaryRowProps> = (props: TableProcessSummaryRowProps) => {

  const rows: any = [];
  const userGroup = props.user?.userGroup;
  const permission = (userGroup?.canCost || userGroup?.canFinance) ? true : false;
  const navigate = useNavigate();
  const handleNavigate = (type: string, id?: string) => {
    //NOTE: Check can approve 
    switch (type) {
      case "farm":
        userGroup?.canApprove ? navigate(`/process-product-receipt-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-product-receipt-edit/${id}`);
        break;
      case "random":
        userGroup?.canApprove ? navigate(`/process-random-inspection-create/${id}`, { state: { from: 'order' } }) : navigate(`/process-random-inspection-create/${id}`);
        break;
      case "bake":
        userGroup?.canApprove ? navigate(`/process-bake-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-bake-edit/${id}`);
        break;
      case "packaging":
        userGroup?.canApprove ? navigate(`/process-packaging-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-packaging-edit/${id}`);
        break;
      case "shipping":
        userGroup?.canApprove ? navigate(`/process-shipping-edit/${id}`, { state: { from: 'order' } }) : navigate(`/process-shipping-edit/${id}`);
        break;
      case "eliminate":
        navigate(`/process-eliminate-create/${id}`, { state: { from: 'order' } });
        break;
    }
  };

  const calShipInBake = (bake: IBakeDetail) => {
    let _ship = 0;
    bake?.packagings?.forEach((pack: IPackagingDetail) => {
      if (pack?.packaging?.shipping?.length === 0) {
        _ship += 1;
      } else {
        _ship += (pack?.packaging?.shipping?.length || 1);
      }
    });
    return _ship;
  };

  // NOTE: cal row span for all farm
  const calLongestInFarm = (farm: IFarmProductReceipt) => {
    const _random = farm?.randomInspections?.length || 1;
    let _bake = 0;
    let _pack = 0;
    let _ship = 0;

    farm?.randomInspections?.forEach((random: IRandomInspection) => {
      const PackFromRandom = random?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake);
      //NOTE: find bake length that not have packaging or bake that have packagind detail with isBake
      const BakeInRandom = random?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length === 0) || (bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))));
      //NOTE: cal bake
      if (BakeInRandom && BakeInRandom?.length > 0) {
        _bake += ((BakeInRandom?.length || 1) + (PackFromRandom?.length || 0));
      } else {
        _bake += 1;
      }
      //NOTE: cal pack in random
      if (PackFromRandom && PackFromRandom?.length > 0) {
        _pack += (PackFromRandom?.length || 0);
      }
      else {
        if (BakeInRandom?.length === 0) {
          _pack += 1;
          _ship += 1;
        }
      }
      //NOTE: cal ship in random
      random?.packagings?.forEach((pack: IPackagingDetail) => {
        if (!pack?.isBake) {
          if (pack?.packaging?.shipping?.length === 0) {
            _ship += 1;
          } else {
            _ship += (pack?.packaging?.shipping?.length || 0);
          }
        }
      });

      //NOTE: cal pack in bake
      random?.bakes?.forEach((bake) => {
        const PackFromBake = bake?.packagings?.filter((pack: IPackagingDetail) => pack?.isBake);
        if (PackFromBake && PackFromBake?.length > 0) {
          _pack += (PackFromBake?.length || 0);
        } else {
          _pack += 1;
          _ship += 1;
        }

        //NOTE: cal ship in bake
        bake?.packagings?.forEach((pack: IPackagingDetail) => {
          if (pack?.isBake) {
            if (pack?.packaging?.shipping?.length === 0) {
              _ship += 1;
            } else {
              _ship += (pack?.packaging?.shipping?.length || 0);
            }
          }
        });
      });
    });
    if (_random > _bake && _random > _pack && _random > _ship) {
      return _random;
    } else if (_bake > _pack && _bake > _ship) {
      return _bake;
    } else if (_ship < _pack) {
      return _pack;
    } else { return _ship; }
  };
  //  NOTE: cal row span for all bake 
  const calLongestInBake = (packagings: IPackagingDetail[]) => {
    const _pack = packagings?.length || 1;
    let _ship = 0;
    packagings?.forEach((pack: IPackagingDetail) => {
      if (pack?.packaging?.shipping?.length === 0) {
        _ship += 1;
      } else {
        _ship += (pack?.packaging?.shipping?.length || 1);
      }
    });
    return _pack > _ship ? _pack : _ship;
  };

  //  NOTE: cal row span for all random
  const calLonhestInRandom = (randoms: IRandomInspection) => {
    // NOTE: Find all bake length
    let _bake = 0;
    let _pack = 0;
    let _ship = 0;
    //NOTE: all pack in not bake
    const PackFromRandomLength = randoms?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake)?.length;
    //NOTE: find bake length that not have packaging or bake that have packagind detail with isBake
    const BakeLength = randoms?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length === 0) || (bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))))?.length;

    //NOTE: find bake that have pack
    const _BakeHavePackLength = randoms?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))))?.length;
    //NOTE: count pack in bake
    const _packInBake = randoms?.bakes?.reduce((accBake, bake: IBakeDetail) => {
      //NOTE: all pack in bake
      const bakeCount = bake?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
        if (pack?.isBake) {
          return accPack + 1;
        } else {
          return accPack;
        }
      }, 0);
      return accBake + (bakeCount || 0);
    }, 0);

    //NOTE: pack in not bake that have ship
    const _PackFromRandomLength = randoms?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake && pack?.packaging?.shipping && (pack?.packaging?.shipping?.length > 0))?.length;
    // NOTE: find all ship in not bake
    const _shipInNotBake = randoms?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
      if (pack?.id && !pack?.isBake && !pack?.packaging?.shipping) {
        return accPack + 1;
      } else {
        return accPack;
      }
    }, 0);

    //NOTE: count pack that have ship in bake
    const _packHaveShipInBake = randoms?.bakes?.reduce((accBake, bake: IBakeDetail) => {
      //NOTE: all pack in bake
      const bakeCount = bake?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
        if (pack?.isBake && pack?.packaging?.shipping && (pack?.packaging?.shipping?.length > 0)) {
          return accPack + 1;
        } else {
          return accPack;
        }
      }, 0);
      return accBake + (bakeCount || 0);
    }, 0);

    // NOTE: count ship in bake
    const _shipInBake = randoms?.bakes?.reduce((accBake, bake: IBakeDetail) => {
      //NOTE: all pack in bake
      const bakeCount = bake?.packagings?.reduce((accPack, pack: IPackagingDetail) => {
        if (pack?.isBake && pack?.packaging?.shipping && (pack?.packaging?.shipping?.length > 0)) {
          return accPack + (pack?.packaging?.shipping?.length || 1);
        } else {
          return accPack;
        }
      }, 0);
      return accBake + (bakeCount || 0);
    }, 0);

    //NOTE: bake = all bake +  all pack in not bake
    _bake = ((BakeLength || 0) + (PackFromRandomLength || 0)) || 1;
    // NOTE: pack = (all bake - bake have pack) + random have not pack + random have pack
    _pack = (Number(BakeLength || 0) - Number(_BakeHavePackLength || 0)) + Number(_packInBake) + Number(PackFromRandomLength || 0);
    // NOTE: ship = bake have pack + bake have not pack
    // _ship = Number(_pack) - Number(_PackFromRandomLength || 0) + Number(_shipInNotBake || 0) - Number(_packHaveShipInBake) + Number(_shipInBake || 0);
    _ship = Number(_pack) + Number(_PackFromRandomLength || 0) + Number(_shipInNotBake || 0) - Number(_packHaveShipInBake) + Number(_shipInBake || 0);

    if ((_bake > _pack && _bake > _ship)) {
      return _bake;
    }
    else if (_pack > _ship) {
      return _pack;
    }
    else {
      return _ship;
    }
  };

  const formatNumber = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  // NOTE: Section Farm in table
  const farmSection = (rowSpan: number, farm: IFarmProductReceipt) => {
    return <>
      <TableCell rowSpan={rowSpan} sx={{ ...commonStyles() }}>
        <Link
          component="button"
          variant="body2"
          sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
          onClick={() => {
            handleNavigate("farm", farm?.id);
          }}
        >
          {`${farm?.docNo} ${farm?.status ? documentStatus?.[farm?.status]?.name : ""}`}
        </Link>
      </TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{props.order?.product?.name}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{farm?.agent?.name}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{formatNumber(farm?.basketAmount || 0)}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{`${formatNumber(farm?.totalWeight || 0)} (${formatNumber(farm?.pricePerKg || 0)})`}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(farm?.grandTotal || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{`${formatNumber(farm?.preWeighingWeight || 0)}`}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles(true)}>{`${formatNumber(farm?.lostM || 0)}`}</TableCell>
    </>;
  };

  // NOTE: Section Random in table
  const randomSection = (rowSpan: number, random: IRandomInspection, farm: IFarmProductReceipt) => {
    return <>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>
        <Link
          component="button"
          variant="body2"
          sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
          onClick={() => {
            handleNavigate("random", random?.id);
          }}
        >
          {`${random?.docNo} ${random?.status ? documentStatus?.[random?.status]?.name : ""}`}
        </Link></TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{farm?.vapourHeatTreatment?.name}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{formatNumber(random?.actualWeight || 0)}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>
        {`${formatNumber(random?.out || 0) || ""} | ${formatNumber(random?.claimsWeight || 0) || ""} | ${formatNumber(random?.totalInspectionWeight || 0) || ""}`}
      </TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(random?.cutPoleWeight || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(random?.lostM || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles(true)}>{formatNumber(random?.totalRemainingWeight || 0)}</TableCell>
    </>;
  };
  // NOTE: Section Bake in table
  const bakeSection = (rowSpan: number, bake: IBakeDetail) => {
    return <>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}> <Link
        component="button"
        variant="body2"
        sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
        onClick={() => {
          handleNavigate("bake", bake?.bake?.id);
        }}
      >
        {`${bake?.bake?.docNo} ${bake?.bake?.status}`}
      </Link></TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{`${(bake?.bake?.eliminateWeight ? formatNumber(bake?.bake?.eliminateWeight || 0) : "-")} | ${(bake?.bake?.censorInspecWeight ? formatNumber(bake?.bake?.censorInspecWeight?.split(',').map(Number)?.reduce((acc, curr) => acc + Number(curr || 0), 0) || 0) : "-")} | ${(bake?.bake?.issueWeight ? formatNumber(bake?.bake?.issueWeight || 0) : "-")}`}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(bake?.bake?.lostM || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(bake?.bake?.vhtCost || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(bake?.bake?.vat7 || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{permission ? formatNumber(bake?.bake?.vat3 || 0) : "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles(true)}>{permission ? formatNumber(bake?.bake?.totalVht || 0) : "-"}</TableCell>
    </>;
  };

  // NOTE: Section Pack in table
  const packSection = (rowSpan: number, pack: IPackagingDetail) => {
    return <>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}> <Link
        component="button"
        variant="body2"
        sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
        onClick={() => {
          handleNavigate("packaging", pack?.packaging?.id);
        }}
      >
        {`${pack?.packaging?.docNo || "-"} ${pack?.packaging?.status ? documentStatus[pack?.packaging?.status]?.name : ""}`}
      </Link></TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{formatNumber(pack?.quantity || 0) || "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles()}>{formatNumber(pack?.totalBox || 0) || "-"}</TableCell>
      <TableCell rowSpan={rowSpan} sx={commonStyles(true)}>{permission ? formatNumber(pack?.materialCost || 0) : "-"}</TableCell>
    </>;
  };

  // NOTE: Section Ship in table
  const shipSection = (ship: IShippingDetail) => {
    return <>
      <TableCell sx={commonStyles()}>
        <Link
          component="button"
          variant="body2"
          sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
          onClick={() => {
            handleNavigate("shipping", ship?.shipping?.id);
          }}
        >
          {`${ship?.shipping?.docNo} ${ship?.shipping?.status ? documentStatus[ship?.shipping?.status]?.name : ""}`}
        </Link>
      </TableCell>
      <TableCell sx={commonStyles()}>{ship?.shipping?.type?.name || "-"}</TableCell>
      <TableCell sx={commonStyles()}>{ship?.quantity || "-"}</TableCell>
      <TableCell sx={commonStyles()}>{permission ? formatNumber(ship?.shipping?.nonVat || 0) : "-"}</TableCell>
      <TableCell sx={commonStyles()}>{permission ? formatNumber(ship?.shipping?.vat || 0) : "-"}</TableCell>
      <TableCell sx={commonStyles()}>{permission ? formatNumber(ship?.shipping?.vat7 || 0) : "-"}</TableCell>
      <TableCell sx={commonStyles()}>{permission ? formatNumber(ship?.shipping?.vat3 || 0) : "-"}</TableCell>
      <TableCell sx={commonStyles()}>{permission ? formatNumber(ship?.shipping?.totalAmount || 0) : "-"}</TableCell>
      <TableCell sx={commonStyles(true)}>{permission ? formatNumber(ship?.shipping?.costPerBox || 0) : "-"}</TableCell>
    </>;
  };


  // NOTE: Section Eliminate in table
  const eliminateSection = (longestInOrder: number) => {
    return <TableCell rowSpan={longestInOrder} sx={{ ...commonStyles() }}>
      {!props.order?.eliminate ? "ไม่มีข้อมูลขายตลาด" : <Link
        component="button"
        variant="body2"
        sx={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
        onClick={() => {
          handleNavigate("eliminate", props.order?.eliminate?.id);
        }}
      >
        {`${(props.order?.sumPriceEliminate)?.toLocaleString() || 0}`} <Box>
          {props.order?.eliminate?.status && documentStatus?.[props.order?.eliminate?.status]?.name ? documentStatus?.[props.order?.eliminate?.status]?.name : "draft"}
        </Box>
      </Link>}
    </TableCell>;
  };

  const calLongestInOrder = () => {
    let longInFarm = 0;
    props.order?.farmProductReceipts?.forEach((farm: IFarmProductReceiptWithcostPerBox) => {
      longInFarm += calLongestInFarm(farm);
    });

    if (props.order?.farmProductReceipts?.length && longInFarm > props.order?.farmProductReceipts?.length) {
      return longInFarm;
    } else {
      return props.order?.farmProductReceipts?.length;
    }
  };


  // NOTE:  Loop through each farm
  props.order?.farmProductReceipts?.forEach((farm: IFarmProductReceiptWithcostPerBox, farmIndex: number) => {
    let inspectionCount = farm?.randomInspections?.length || 0;
    const _calLongestFarm = calLongestInFarm(farm) || 1;
    const longestInOrder = calLongestInOrder();
    if (inspectionCount === 0) {
      rows.push(
        <>
          <TableRow key={farm?.id}>
            {/*  NOTE: farm */}
            {farmSection(_calLongestFarm, farm)}

            {/*  NOTE: no data */}
            <TableCell colSpan={props.quality?.length} sx={commonStyles(true)}>ไม่มีข้อมูลตรวจคุณภาพ</TableCell>
            <TableCell colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>
            <TableCell colSpan={props.pack?.length} sx={commonStyles(true)}>ไม่มีข้อมูลแพ็ค</TableCell>
            <TableCell colSpan={props.shipping?.length} sx={commonStyles(true)}>ไม่มีข้อมูล Shipping</TableCell>

            {/*  NOTE: eliminate */}
            {farmIndex === 0 && eliminateSection(longestInOrder || 0)}
          </TableRow>
        </>
      );
    } else {
      farm?.randomInspections?.forEach((random: IRandomInspection, randomIndex: number) => {
        const BakeInRandom = random?.bakes?.filter((bake: IBakeDetail) => ((bake?.id && bake?.packagings && bake?.packagings?.length === 0) || (bake?.id && bake?.packagings && bake?.packagings?.length > 0 && bake?.packagings?.find((pack: IPackagingDetail) => pack?.isBake))));
        let bakeCount = BakeInRandom?.length || 0;
        const _logestRandom = calLonhestInRandom(random);
        //NOTE: filter pack only isBake is false
        const PackFromRandom = random?.packagings?.filter((pack: IPackagingDetail) => pack?.id && !pack?.isBake);
        const _calLongestBakeForRandom = calLongestInBake(PackFromRandom || []);
        let _packCount = PackFromRandom?.length || 0;
        //NOTE: Check if random has bakes
        if (bakeCount === 0) {
          //NOTE: Check if random has packs
          if (PackFromRandom && _packCount > 0) {
            PackFromRandom?.forEach((pack, packIndex) => {
              if (!pack?.isBake) {     // Check if pack has ships
                if (!pack?.packaging?.shipping || pack?.packaging?.shipping?.length === 0) {
                  rows.push(
                    <TableRow key={`random-${farm.id}-${random.id}-${pack.id}`}>
                      {/* NOTE: farm */}
                      {randomIndex === 0 && packIndex === 0 && farmSection(_calLongestFarm, farm)}

                      {/* NOTE: Random  */}
                      {packIndex === 0 && randomSection(_logestRandom, random, farm)}

                      {/* NOTE: Bake */}
                      {packIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}

                      {/* NOTE: Pack */}
                      {packSection(1, pack)}

                      {/*NOTE: No data for Shipping */}
                      {<TableCell rowSpan={1} colSpan={props.shipping?.length} sx={commonStyles(true)}>ไม่มีข้อมูล Shipping</TableCell>}

                      {/*  NOTE: eliminate */}
                      {farmIndex === 0 && randomIndex === 0 && packIndex === 0 && eliminateSection(longestInOrder || 0)}
                    </TableRow>
                  );
                } else {
                  // NOTE: cal all pack row
                  const _shipSpanForRandom = pack?.packaging?.shipping?.length || 1;
                  pack?.packaging?.shipping?.forEach((ship: IShippingDetail, shipIndex: number) => {
                    rows.push(
                      <TableRow key={`random-${farm.id}-${random.id}-${pack.id}-${ship.id}`}>
                        {/* NOTE: farm */}
                        {randomIndex === 0 && packIndex === 0 && shipIndex === 0 &&
                          farmSection(_calLongestFarm, farm)
                        }

                        {/* NOTE: Random  */}
                        {packIndex === 0 && shipIndex === 0 && randomSection(_logestRandom, random, farm)}

                        {/* NOTE: Bake */}
                        {packIndex === 0 && shipIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}

                        {/* NOTE: Pack */}
                        {shipIndex === 0 && packSection(_shipSpanForRandom, pack)}

                        {/* NOTE: shipping*/}
                        {shipSection(ship)}

                        {/*  NOTE: eliminate */}
                        {farmIndex === 0 && randomIndex === 0 && packIndex === 0 && shipIndex === 0 && eliminateSection(longestInOrder || 0)}
                      </TableRow>
                    );
                  });
                }
              }
            });
          } else {
            rows.push(
              <TableRow key={`${farm.id}-${random.id}`}>
                {/*NOTE: farm */}
                {randomIndex === 0 && farmSection(_calLongestFarm, farm)}

                {/*NOTE: random */}
                {randomSection(1, random, farm)}

                {/* NOTE: no data */}
                <TableCell colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>
                <TableCell colSpan={props.pack?.length} sx={commonStyles(true)}>ไม่มีข้อมูลแพ็ค</TableCell>
                <TableCell colSpan={props.shipping?.length} sx={commonStyles(true)}>ไม่มีข้อมูล Shipping</TableCell>

                {/*  NOTE: eliminate */}
                {farmIndex === 0 && randomIndex === 0 && eliminateSection(longestInOrder || 0)}
              </TableRow>
            );
          }
        } else {
          BakeInRandom?.forEach((bake, bakeIndex) => {
            let packCount = bake?.packagings?.length || 0;
            const _packCountInBake = packCount === 0 ? 1 : packCount;
            if (packCount === 0) {
              rows.push(
                <TableRow key={`${farm.id}-${random.id}-${bake.id}`}>
                  {/* NOTE: farm */}
                  {randomIndex === 0 && bakeIndex === 0 && farmSection(_calLongestFarm, farm)}

                  {/* NOTE: random */}
                  {bakeIndex === 0 && randomSection(_logestRandom, random, farm)}

                  {/* NOTE: bake */}
                  {bakeSection(1, bake)}

                  {/* NOTE: no data */}
                  <TableCell colSpan={props.pack?.length} sx={commonStyles(true)}>ไม่มีข้อมูลแพ็ค</TableCell>
                  <TableCell colSpan={props.shipping?.length} sx={commonStyles(true)}>ไม่มีข้อมูล Shipping</TableCell>

                  {/*  NOTE: eliminate */}
                  {farmIndex === 0 && randomIndex === 0 && bakeIndex === 0 && eliminateSection(longestInOrder || 0)}
                </TableRow>
              );
            } else {
              bake?.packagings?.forEach((pack, packIndex) => {
                const shipSpan = pack?.packaging?.shipping?.length || 0;
                const _shipSpan = shipSpan === 0 ? 1 : shipSpan;
                const _calShipInbake = calShipInBake(bake);
                const _RandomInPack = _packCountInBake > _calShipInbake ? _packCountInBake : _calShipInbake;
                // NOTE: Check if pack has ships
                if (shipSpan === 0) {
                  rows.push(
                    <TableRow key={`${farm.id}-${random.id}-${bake.id}-${pack.id}`}>
                      {/* NOTE: farm */}
                      {randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && farmSection(_calLongestFarm, farm)}

                      {/* NOTE: Random  */}
                      {bakeIndex === 0 && packIndex === 0 && randomSection(_logestRandom, random, farm)}

                      {/* NOTE: Bake */}
                      {packIndex === 0 && bakeSection(_RandomInPack, bake)}

                      {/* NOTE: pack */}
                      {packSection(1, pack)}

                      {/* NOTE: No data for Shipping */}
                      <TableCell colSpan={props.shipping?.length} sx={commonStyles(true)}>ไม่มีข้อมูล Shipping</TableCell>

                      {/*  NOTE: eliminate */}
                      {farmIndex === 0 && randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && eliminateSection(longestInOrder || 0)}
                    </TableRow>
                  );
                } else {
                  // NOTE: filter pack with isBake
                  const _packIsBake = bake?.packagings?.filter((pack: IPackagingDetail) => pack?.isBake);
                  const _longestInBake = calLongestInBake(_packIsBake || []);
                  // NOTE: Loop through each ship in the pack
                  pack?.packaging?.shipping?.forEach((ship: IShippingDetail, shipIndex: number) => {
                    rows.push(
                      <TableRow key={`${farm.id}-${random.id}-${bake.id}-${pack.id}-${ship.id}`}>
                        {/* NOTE: farm */}
                        {randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && shipIndex === 0 && farmSection(_calLongestFarm, farm)}

                        {/* NOTE: random */}
                        {bakeIndex === 0 && packIndex === 0 && shipIndex === 0 &&
                          randomSection(_logestRandom, random, farm)}

                        {/* NOTE: bake */}
                        {packIndex === 0 && shipIndex === 0 && bakeSection(_longestInBake, bake)}

                        {/* NOTE: pack */}
                        {shipIndex === 0 && packSection(_shipSpan, pack)}

                        {/* NOTE: shipping*/}
                        {shipSection(ship)}

                        {/*  NOTE: eliminate */}
                        {farmIndex === 0 && randomIndex === 0 && bakeIndex === 0 && packIndex === 0 && shipIndex === 0 && eliminateSection(longestInOrder || 0)}
                      </TableRow>);
                  });
                }
              });
            }
          });

          if (PackFromRandom && _packCount > 0) {
            PackFromRandom?.forEach((pack, packIndex) => {
              if (!pack?.isBake) {
                if (!pack?.packaging?.shipping || pack?.packaging?.shipping?.length === 0) {
                  rows.push(
                    <TableRow key={`random-${farm.id}-${random.id}-${pack.id}-bake`}>

                      {/* NOTE: Bake */}
                      {packIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}

                      {/* NOTE: Pack */}
                      {packSection(1, pack)}

                      {/* NOTE: No data for Shipping */}
                      {<TableCell rowSpan={1} colSpan={props.shipping?.length} sx={commonStyles(true)}>ไม่มีข้อมูล Shipping</TableCell>}

                    </TableRow>
                  );
                } else {
                  // NOTE: cal all pack row
                  const _shipSpanForRandom = pack?.packaging?.shipping?.length || 1;
                  pack?.packaging?.shipping?.forEach((ship: IShippingDetail, shipIndex: number) => {
                    rows.push(
                      <TableRow key={`random-${farm.id}-${random.id}-${pack.id}-${ship.id}`}>
                        {/* NOTE: Bake */}
                        {packIndex === 0 && shipIndex === 0 && <TableCell rowSpan={_calLongestBakeForRandom} colSpan={props.vht?.length} sx={commonStyles(true)}>ไม่มีข้อมูลอบ</TableCell>}

                        {/* NOTE: Pack */}
                        {shipIndex === 0 && packSection(_shipSpanForRandom, pack)}

                        {/* NOTE: shipping*/}
                        {shipSection(ship)}
                      </TableRow>
                    );
                  });
                }
              }
            });
          }
        }
      });
    }

    // NOTE: Summary
    if (farmIndex === (props.order?.farmProductReceipts?.length || 1) - 1) {
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'right', fontWeight: 'bold', borderRightColor: 'transparent' }}>จำนวนทั้งหมด (กล่อง)</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{formatNumber(props?.order?.totalBox || 0)}</TableCell>
        </TableRow>
      );
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'right', fontWeight: 'bold', borderRightColor: 'transparent' }}>ราคาต่อกล่อง</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{permission ? formatNumber(props?.order?.costPerBox || 0) : "-"}</TableCell>
        </TableRow>
      );
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'right', fontWeight: 'bold', borderRightColor: 'transparent' }}>ต้นทุนทั้งหมด</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{permission ? formatNumber(props?.order?.totalCostInOrder || 0) : '-'}</TableCell>
        </TableRow>
      );
      rows.push(
        <TableRow key={`summary`}>
          <TableCell colSpan={props?.allColSpan} sx={{ ...commonStyles(), textAlign: 'right', fontWeight: 'bold', borderRightColor: 'transparent' }}>กำไร</TableCell>
          <TableCell sx={{ ...commonStyles(), fontWeight: 'bold' }}>{permission ? formatNumber(props?.order?.profit || 0) : "-"}</TableCell>
        </TableRow>
      );
    }
  });
  return rows;
};

export default TableProcessSummaryRow;
