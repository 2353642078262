import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import orderService, { IOrder } from "../Services/OrderService";
import { IBake } from "../Services/BakeService";
import bakeService from "../Services/BakeService";
import receiveService from "../../Inventory/Services/ReceiveService";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import BakeCreateView from "../Views/BakeCreateView";
import loginService from "../../Login/Services/LoginService";
import clearState from "../../../utils/resetState";
import invoiceService, { IInvoice } from "../Services/InvoiceService";


const BakeCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [orders, setOrders] = useState<IOrder[]>();
  const [invoices, setInvoices] = useState<IInvoice[]>();

  useEffect(() => {
    getWarehouses();
    checkPermissionActionButton();
    getInvoice();
    if (id) {
      getOne(id);
    }
    return () => {
      clearState(bakeService, ["bake"]);
    };
  }, [id]);

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await bakeService.getOne(id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while getting the data", error);
    }
  };

  const getOrder = async (invoiceNo?: string) => {
    try {
      const result = await orderService.getAll(invoiceNo);
      if (result) {
        setOrders(result ?? [] as IOrder[]);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูล", error);
    }
  };

  const getWarehouses = async () => {
    try {
      await receiveService.getWarehouses();
    } catch (error) {
      console.error(error);
    }
  };

  const create = async (data: IBake, uploadFile: IUploadFile) => {
    try {
      const result = await bakeService.create(data, uploadFile);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-bake-edit/${result.id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const update = async (data: IBake, oldImage: IUploadFile[], uploadFile: IUploadFile[], deleteFile: IUploadFile[]) => {
    try {
      const _uploadFile = oldImage === uploadFile ? undefined : uploadFile;
      const res = await bakeService.update(data.id || "", data, _uploadFile);
      if (res) {
        if (deleteFile && deleteFile.length > 0 && (data.status !== 'approved' && data.status !== 'rejected')) {
          const deletePromises = deleteFile.map((file: any) => {
            const isPdf = file.name && file.name.toLowerCase().endsWith('.pdf');
            return receiveService.deleteImages({
              file: file,
            }, isPdf);
          });
          await Promise.all(deletePromises);
        }
        getOne(data.id ?? '');
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
  };

  const remove = async (id: string) => {
    try {
      await bakeService.delete(id);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate("/process-bake");
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการลบ", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-bake");
    if (result) {
      setPermission(result);
    }
  };

  const getInvoice = async () => {
    try {
      setIsLoading(true);
      const result = await invoiceService.getAll();
      if (result) {
        setInvoices(result ?? [] as IInvoice[]);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการดึงข้อมูล", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return <BakeCreateView
    isLoading={isLoading}
    onCreate={create}
    onUpdate={update}
    onRemove={remove}
    bake={bakeService.getBake ?? {} as IBake}
    user={loginService.userLoginedData ?? {} as IUserDetail}
    permission={permission ?? {} as IUserDetail}
    orders={orders ?? [] as IOrder[]}
    randomInspections={[]}
    invoices={invoices ?? [] as IInvoice[]}
    onGetOrders={getOrder}
  />;
});

export default BakeCreateController;