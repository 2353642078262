import { FC } from "react";
import { ILossReport, ILossReports, IReportCriteria } from "../Services/ReportService";
import ReportListView from "../../../components/ReportListView";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface LossReportListViewProps {
  isLoading: boolean;
  lossReportData: ILossReports;
  getLossReportData: (criteria: IReportCriteria) => void;
}

const LossReportListView: FC<LossReportListViewProps> = (props) => {
  const navigate = useNavigate();
  const lossReportColumns = [
    { label: 'วันที่', render: (row: ILossReport) => row.orderDate ? dayjs(row.orderDate).format("DD/MM/YYYY HH:mm") : "" },
    { label: 'Invoice No.', render: (row: ILossReport) => row.invoiceNo ?? "" },
    { label: 'ชื่อโปรเจกต์', render: (row: ILossReport) => row.projectName ?? "" },
    {
      label: 'สูญหายจากรับจากสวน', render: (row: ILossReport) => (
        <strong>{row.totalEliminateWeightFromFarm ? row.totalEliminateWeightFromFarm.toFixed(2).toString() : "0.00"}</strong>
      )
    },
    {
      label: 'สูญหายจากตรวจสอบคุณภาพ', render: (row: ILossReport) => (
        <strong>{row.totalEliminateWeightFromRandom ? row.totalEliminateWeightFromRandom.toFixed(2).toString() : "0.00"}</strong>
      )
    },
    {
      label: 'สูญหายจากอบ', render: (row: ILossReport) => (
        <strong>{row.totalEliminateWeightFromBake ? row.totalEliminateWeightFromBake.toFixed(2).toString() : "0.00"}</strong>
      )
    },
    {
      label: 'รวมน้ำหนักสูญหาย', render: (row: ILossReport) => (
        <strong>{row.totalEliminateWeight ? row.totalEliminateWeight.toFixed(2).toString() : "0.00"}</strong>
      )
    },
    {
      label: 'รวมมูลค่าทั้งหมด', render: (row: ILossReport) => (
        <strong>{row.totalPriceLoss ? thaiFormatNumber(row.totalPriceLoss.toString()) : "0.00"}</strong>
      )
    },

  ];

  const totalValues = [
    { label: "ต้นทุน", value: props.lossReportData?.totalValue?.totalCost, percentage: props.lossReportData?.totalValue?.costPercentChange },
    { label: "ยอดขาย", value: props.lossReportData?.totalValue?.totalSales, percentage: props.lossReportData?.totalValue?.salesPercentChange },
    { label: "กำไร", value: props.lossReportData?.totalValue?.totalProfit, percentage: props.lossReportData?.totalValue?.profitPercentChange },
    { label: "ยอดสูญเสีย", value: props.lossReportData?.totalValue?.totalLoss, percentage: props.lossReportData?.totalValue?.lossPercentChange },
  ];

  const rowActions = (row: ILossReport) => (
    <IconButton
      onClick={() => {
        navigate(`/process-order-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  return (
    <>

      <ReportListView
        isLoading={props.isLoading}
        reportData={props.lossReportData}
        getReportData={props.getLossReportData}
        reportTitle="รายงานยอดสูญเสีย"
        totalValues={totalValues}
        reportColumns={lossReportColumns}
        rowActions={rowActions}
      />
    </>
  );
};

export default LossReportListView;
