
import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IGrouping } from "../../Inventory/Services/GroupingService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { IRandomInspection } from "./RandomInspectionService";
import { IProduct } from "./ProductService";
import { IFarmProductReceipt, IFarmProductReceiptWithcostPerBox } from "./FarmProductReceipt";
import { IGrade } from "./GradeService";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import { IEliminate } from "./EliminateService";
import { IBake } from "./PackagingService";
import { Dayjs } from "dayjs";
import { IInvoice } from "./InvoiceService";

export interface IOrder {
  id?: string;
  invoiceNo?: IInvoice | undefined;
  product?: IProduct | undefined;
  status?: number;
  quantityBox?: number;
  remarks?: string;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
  farmProductReceipts?: IFarmProductReceipt[] | undefined;
  randomInspections?: IRandomInspection[] | undefined;
  bakes?: IBake[] | undefined;
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  name?: string;
  projectName?: string;
  wage?: number;
  agent?: IAgent | undefined;
  orderDetails?: IOrderDetail[] | undefined;
  totalQuantitiesByGrade?: ITotalQuantitiesByGrade[] | undefined;
  isComplete?: boolean;

}

export interface IOrderWithoutRandom {
  id?: string;
  invoiceNo?: IInvoice | undefined;
  product?: IProduct | undefined;
  status?: number;
  quantityBox?: number;
  remarks?: string;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createdAt?: string;
  updatedAt?: string;
  farmProductReceipts?: Omit<IFarmProductReceiptWithcostPerBox, 'order,farmExpenseDetail'>[] | undefined;
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  name?: string;
  projectName?: string;
  wage?: number;
  agent?: IAgent | undefined;
  orderDetails?: IOrderDetail[] | undefined;
  totalQuantitiesByGrade?: ITotalQuantitiesByGrade[] | undefined;
  isComplete?: boolean;
  price?: number;
  totalCostInOrder?: number;
  profit?: number;
  totalBox?: number;
  costPerBox?: number;
  orderEstimatePrice?: IOrderEstimatePrice | undefined;
  eliminate?: IEliminate | undefined;
  sumPriceEliminate?: number;
}

export interface ITotalQuantitiesByGrade {
  gradeId?: string;
  gradeName?: string;
  totalQuantity?: number;
  value?: number;
}

export interface IAgent {
  id?: string;
  name?: string;
}

export interface IRefOrder {
  id?: string;
  invoiceNo?: IInvoice | undefined;
  transportType?: number;
  destination?: string;
  plateNumber?: string;
  origin?: string;
  status?: number;
  quantityBox?: number;
  remarks?: string;
  product?: IProduct | undefined;
  dateFrom?: Dayjs;
  dateTo?: Dayjs;
  name?: string;
  projectName?: string;
  orderDetails?: IOrderDetail[] | undefined;
}

export interface IOrderCriteria {
  dateFrom?: string;
  dateTo?: string;
  invoiceNo?: string;
  projectName?: string;
  transportType?: number;
  destination?: string;
  product?: string;
  status?: boolean | number;
  page?: number;
  limit?: number;
  supplier?: string;
}

export interface IOrderDetail {
  id?: string;
  size?: string;
  amount?: number;
  grade?: IGrade | undefined;
  // TODO: fix this when material group is ready
  // materialGroup?: string;
  Order?: string;
  quantity?: number;
  createdBy?: IUser | undefined;
  updatedBy?: IUser | undefined;
  createdAt?: string;
  updatedAt?: string;

}

export interface IOrderListView {
  datas?: IOrder[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

export interface IOrderEstimatePrice {
  id?: string;
  totalCostPerBox?: number;
  totalCost?: number;
  totalWeight?: number;
  pricePerKg?: number;
  totalPrice?: number;
  transportCost?: number;
  wage?: number;
  otherExpenses?: number;
  vht?: number;
  totalPackageCost?: number;
  shippingCost?: number;
  shippingCostPerBox?: number;
  totalBox?: number;
  order?: string;
  isDelete?: boolean;
}

class OrderService {
  orders: IOrderListView | undefined;
  order: IOrderWithoutRandom | undefined;
  grouping: IGrouping[] | undefined;
  grades: IGrade[] | undefined;
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  supplierTypeList: ISupplierType[] | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  //#region Order
  async getAll(invoiceNo?: string) {
    try {
      const url = invoiceNo ? `orders?invoiceNo=${invoiceNo}` : "orders";
      const result = await apiService.get(url);
      return result;
    } catch (error) {
      throw error;
    }
  }


  async getOne(id: string) {
    try {
      this.order = await apiService.get(`orders/${id}`);
      return this.order;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IOrderCriteria,) {
    try {
      this.orders = await apiService.post("orders/criteria", criteria);
      return this.orders;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IOrder) {
    try {
      const Order = await apiService.post("orders", data);
      return Order;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IOrder) {
    try {
      const Order = await apiService.put(`orders/${id}`, data);
      return Order;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      await apiService.delete(`orders/${id}`);
    } catch (error) {
      throw error;
    }
  }

  async getGrade() {
    try {
      this.grades = await apiService.get("grades");
      return this.grades;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  async getAllSupplierType() {
    try {
      const supplierTypes = await apiService.get("supplier/type/all");
      this.supplierTypeList = supplierTypes;
      return this.supplierTypeList;
    } catch (error) {
      throw error;
    }
  }

  async submitOrderEstimatePrice(data: IOrderEstimatePrice) {
    try {
      const estimatePrice = await apiService.post("order-estimate-price", data);
      return estimatePrice;
    } catch (error) {
      throw error;
    }
  }


  get orderData() {
    return this.order;
  }

  get ordersData() {
    return this.orders;
  }

  get gradesData() {
    return this.grades;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }

  get supplierTypeListData() {
    return this.supplierTypeList;
  }

  async submitDicisionEliminate(id: string, status: string, orderId: string, totalEliminateWeight: number, totalSalesWeight: number) {
    try {
      const eliminate = await apiService.put(`eliminates/${id}/submit-decision`, { status, orderId, totalEliminateWeight, totalSalesWeight });
      return eliminate;
    } catch (error) {
      throw error;
    }
  }

  //#endregion
}

const orderService = new OrderService();
export default orderService;