import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { IUserLogined } from "../../Login/Services/LoginService";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IOrder } from "../Services/OrderService";
import { Autocomplete, Stack, FormControl, Breadcrumbs, Button, ButtonProps, Card, CardContent, styled, TextField, Divider, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IBake, IBakeDetail } from "../Services/BakeService";
import { Box, Grid, IconButton, Typography, Link } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "../../../themes";
import { IRandomInspection } from "../Services/RandomInspectionService";
import { v4 as uuidv4 } from "uuid";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImagePreview from "../../../components/ImagePreview";
import DialogConfirm from "../../../components/DialogConfirm";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { useFileManagement } from "../../../utils/fileHandlers";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { IInvoice } from "../Services/InvoiceService";

interface BakeCreateViewProps {
  orders: IOrder[];
  randomInspections: any[];
  bake: IBake;
  permission: IUserDetail;
  onCreate: (bake: IBake, fileUpload: IUploadFile) => void;
  onUpdate: (bake: IBake, oldImage: IUploadFile[], fileUpload: IUploadFile[], deleteFile: IUploadFile[]) => void;
  onRemove: (id: string) => void;
  isLoading: boolean;
  user: IUserLogined;
  invoices: IInvoice[];
  onGetOrders: (invoiceNo?: string) => void;
}

const BakeCreateView: FC<BakeCreateViewProps> = (props) => {
  const userGroup = props.user?.userGroup;
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === 'status' || from === "order";
  const isDisable = isFromStatus ? true : false;
  const { handleSubmit, setValue, reset, getValues, watch, formState: { errors }, control } = useForm<IBake>();
  const order = watch('order');
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "details"
  });

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const defaultValue: IBake = {
    censorInspecWeight: '',
    issueWeight: 0,
    totalBeforeBakeWeight: 0,
    // docNo: "",
    eliminateWeight: 0,
    vhtCost: 0,
    totalVht: 0,
    vat3: 0,
    vat7: 0,
    createBy: undefined,
    updateBy: undefined,
    approveBy: undefined,
    image: [],
    createAt: new Date(),
    updateAt: new Date(),
    docDate: dayjs().startOf('day'),
    details: [],
  };


  const [randomInspectionList, setRandomInspectionList] = useState<IRandomInspection[]>([] as IRandomInspection[]);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isReject, setIsReject] = useState(false);
  const [censorInspecWeight, setCensorInspecWeight] = useState([
    { id: uuidv4(), value: 0 },
  ]);

  const {
    selectedFiles,
    images,
    pdfFiles,
    deleteImages,
    setImages,
    setPdfFiles,
    setSelectedFiles,
    setDeleteImages,
    handleFileChange,
    handleFileRemove,
    handleFileRemoveImageList,
    handleFileRemovePdfList,
  } = useFileManagement();

  watch();

  useEffect(() => {
    if (props.bake && props.bake.id) {
      setData(props.bake);
      setValue('order', props.bake?.order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bake]);


  const setData = (data: IBake) => {
    if (data) {
      reset(data);
      // NOTE: may use later
      // if (data.vat7 && +data.vat7 === 0) {
      //   setIsVat7(false);
      // }
      // if (data.vat3 && +data.vat3 === 0) {
      //   setIsVat3(false);
      // }
      if (data?.details && data?.details.length > 0) {
        setRandomInspectionList(data.order?.randomInspections?.filter((x => x.isDelete === false)) || []);
      }
      setImages(data.image ?? [] as any);
      setPdfFiles(data.file ?? [] as any);
      if (data.censorInspecWeight) {
        const cencorInspecWeight = data.censorInspecWeight?.split(',');
        if (cencorInspecWeight) {
          setCensorInspecWeight(cencorInspecWeight.map((item) => ({ id: uuidv4(), value: parseFloat(item.toString()) })));
        }
      } else {
        setCensorInspecWeight([]);
      }
    }
  };


  const onSubmit = () => {
    const data = getValues();
    if (censorInspecWeight && censorInspecWeight.length > 0) {
      data.censorInspecWeight = censorInspecWeight.map((item) => item.value).join(',');
    } else {
      data.censorInspecWeight = '0';
    }
    data.vapourHeatTreatment = data.details?.[0]?.randomInspection?.farm?.vapourHeatTreatment ?? undefined;
    data.issueWeight = data.issueWeight || 0;
    data.eliminateWeight = data.eliminateWeight || 0;
    data.vhtCost = data.vhtCost || 0;

    if (props.bake.id) {
      props.onUpdate({ ...data, status: isFromStatus ? isReject ? "rejected" : "approved" : "waiting" }, images, selectedFiles, deleteImages);
    } else {
      props.onCreate(data, selectedFiles);
    }
    setIsReject(false);
    setSelectedFiles([]);
    setDeleteImages([]);
  };


  const handleSave = handleSubmit(() => {
    if (props.bake.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "update",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const handleDelete = () => {
    setOpenConfirm({
      open: true,
      title: "ลบข้อมูล",
      message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
      color: "error",
      type: "delete",
    });
  };

  const onDelete = () => {
    if (props.bake.id) {
      props.onRemove(props.bake.id);
    }

  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
  };

  const onApprove = () => {
    setOpenConfirm({
      open: true,
      title: "อนุมัติเอกสาร",
      message: "คุณต้องการอนุมัติข้อมูลอบนี้ใช่หรือไม่",
      color: "primary",
      type: "update",
    });
  };

  const onReject = () => {
    setOpenConfirm({
      open: true,
      title: "ปฏิเสธเอกสาร",
      message: "คุณต้องการปฏิเสธข้อมูลอบนี้ใช่หรือไม่",
      color: "error",
      type: "update",

    });
  };

  const setDefaultValue = (data: IOrder) => {
    const currentInvoiceNo = getValues('invoiceNo');

    reset({
      ...defaultValue,
      id: props.bake.id ?? undefined,
      invoiceNo: currentInvoiceNo
    });

    setValue("order", data);
    const filterBake: IBake[] = (data.bakes ?? []).filter((item: IBake) => item.status === "approved" && item.isDelete === false);
    if (data?.randomInspections && data.randomInspections.length > 0) {
      // Collect all randomInspection IDs from filterBake.details
      const existingRandomInspectionIds = new Set<string>();
      filterBake.forEach((bake) => {
        bake.details?.forEach((detail) => {
          if (detail.randomInspection?.id) {
            existingRandomInspectionIds.add(detail.randomInspection.id);
          }
        });
      });

      // Filter randomInspection list to exclude existing IDs
      const randomInspection = data.randomInspections.filter(
        (item: IRandomInspection) => item.status === "approved" && item.isDelete === false && !existingRandomInspectionIds.has(item.id)
      );

      if (randomInspection.length > 0) {
        let weightBeforeBake = 0;
        setValue("details", defaultValue.details);
        randomInspection.forEach((item: IRandomInspection) => {
          append({
            id: "",
            randomInspection: item,
          });

          const totalRemainingWeight = item.totalRemainingWeight?.toString() || "0";
          weightBeforeBake += parseFloat(totalRemainingWeight);
        });

        setValue("totalBeforeBakeWeight", weightBeforeBake);
        setValue("totalAfterBakeWeight", weightBeforeBake);
        setRandomInspectionList(randomInspection);
      } else {
        setRandomInspectionList([]);
      }
    } else {
      setRandomInspectionList([]);
      setValue("details", defaultValue.details);
    }


  };

  // NOTE: may use later
  // const calculateVHT = (vht: number, vat7: boolean, vat3: boolean) => {
  //   const vat7Value = vat7 ? (vht * 7) / 100 : 0;
  //   const vat3Value = vat3 ? (vht * 3) / 100 : 0;
  //   const totalVht = (vht + vat7Value) - vat3Value;
  //   setValue("vat7", vat7Value);
  //   setValue("vat3", vat3Value);
  //   setValue("totalVht", totalVht);
  // };

  const deleteRow = (index: number) => {
    remove(index);
    calculateTotalBeforeBakeWeight(index);
  };

  const calculateTotalBeforeBakeWeight = (index: number) => {
    const totalBeforeBakeWeight = fields.reduce((prev, current, currentIndex) => {
      const totalRemainingWeight = parseFloat(current.randomInspection?.totalRemainingWeight?.toString() ?? '0');
      // If totalRemainingWeight is undefined, default to 0
      const weight = totalRemainingWeight !== undefined ? totalRemainingWeight : 0;
      return currentIndex !== index ? parseFloat(prev.toString() ?? '0') + parseFloat(weight.toString() ?? '0') : prev;
    }, 0);

    setValue('totalBeforeBakeWeight', totalBeforeBakeWeight);
  };

  const calculateTotalAfterBakeWeight = () => {
    const totalBeforeBakeWeight = isNaN(parseFloat(getValues('totalBeforeBakeWeight')?.toString() || '0'))
      ? 0
      : parseFloat(getValues('totalBeforeBakeWeight')?.toString() || '0');

    const issueWeight = isNaN(parseFloat(getValues('issueWeight')?.toString() || '0'))
      ? 0
      : parseFloat(getValues('issueWeight')?.toString() || '0');

    const eliminateWeight = isNaN(parseFloat(getValues('eliminateWeight')?.toString() || '0'))
      ? 0
      : parseFloat(getValues('eliminateWeight')?.toString() || '0');

    const censorInspecWeightValue = censorInspecWeight.reduce((prev, current) => {
      const currentValue = isNaN(current.value) ? 0 : current.value;
      return prev + currentValue;
    }, 0);

    // Calculate totalAfterBakeWeight safely
    const totalAfterBakeWeight = totalBeforeBakeWeight - (issueWeight + eliminateWeight + censorInspecWeightValue);

    // Set the value for totalAfterBakeWeight
    setValue('totalAfterBakeWeight', totalAfterBakeWeight);

  };


  const handleChange = (data: IRandomInspection, previousData: IRandomInspection) => {
    const previousWeightBeforeBake = previousData && previousData.totalRemainingWeight ? parseFloat(previousData.totalRemainingWeight.toString()) : 0;
    const newWeightBeforeBake = data && data.totalRemainingWeight ? parseFloat(data.totalRemainingWeight.toString()) : 0;
    const currentTotalBeforeBakeWeight = parseFloat(getValues('totalBeforeBakeWeight')?.toString() ?? '0');

    const updatedTotalBeforeBakeWeight = currentTotalBeforeBakeWeight - previousWeightBeforeBake + newWeightBeforeBake;
    setValue('totalBeforeBakeWeight', updatedTotalBeforeBakeWeight);
  };

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/process-bake")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.bake.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> : <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>อบ</Typography>
            <Link component="button" onClick={() => navigate("/process-bake")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.bake.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.bake?.id && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                handleDelete();
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {props.bake?.id && <Grid item xs={12} sm={12} md={12} lg={12} mb={3}>
                  <Typography variant="h6">{props.bake?.docNo}</Typography>
                </Grid>}
              </Grid>
              <Box component="form" mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="docDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              disabled={isDisable}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: errors.docDate ? "กรุณาระบุวันที่" : "",
                                }
                              }}
                              label="วันที่"
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => {
                                field.onChange(date ? date.format('YYYY-MM-DD') : null);
                              }}

                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="invoiceNo"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field.value as IInvoice || null}
                          options={props.invoices ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.invoiceNo || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue);
                              props.onGetOrders(newValue.id);
                              setValue('order', undefined);
                              setRandomInspectionList([]);
                            } else {
                              field.onChange(null);
                              setValue('order', undefined);
                              setRandomInspectionList([]);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Invoice No."
                              error={!!errors.invoiceNo}
                              helperText={errors.invoiceNo ? "กรุณาเลือก Invoice" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="order"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisable}
                          value={field.value as IOrder || null}
                          options={props.orders ?? []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.projectName || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              field.onChange(newValue);
                              setDefaultValue(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ชื่อโปรเจกต์"
                              error={!!errors.order}
                              helperText={errors.order ? "กรุณาเลือกโปรเจกต์" : ""}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {
                    randomInspectionList && randomInspectionList.length > 0 &&
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Typography variant="subtitle1">รายการตรวจสอบคุณภาพ</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {fields.map((data: IBakeDetail, index) => (

                          <Grid container spacing={2} key={data.id ?? index} mb={1}>
                            {(!isMobile) && (
                              <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                {fields.length > 1 &&
                                  <IconButton
                                    disabled={isDisable}
                                    color="error"
                                    onClick={() => {
                                      deleteRow(index);
                                      calculateTotalAfterBakeWeight();
                                    }}
                                  >
                                    <RemoveCircleIcon />
                                  </IconButton>
                                }
                              </Grid>
                            )}
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                              <FormControl fullWidth>
                                <Controller
                                  name={`details.${index}.randomInspection`}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => {
                                    const selectedInspectionIds = new Set(fields.map(field => field.randomInspection?.id));
                                    const filteredOptions = randomInspectionList.filter((item: IRandomInspection) => !selectedInspectionIds.has(item.id));
                                    const filterDeletedOptions = filteredOptions.filter((item: IRandomInspection) => item.status === "approved" && item.isDelete === false);
                                    return (
                                      <Autocomplete
                                        {...field}
                                        disabled={isDisable}
                                        options={filterDeletedOptions}
                                        getOptionLabel={(option: any) => {
                                          if (typeof option === 'string') {
                                            return option;
                                          }
                                          if (option.inputValue) {
                                            return option.inputValue;
                                          }
                                          return option.docNo || "";
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                          if (!option || !value) {
                                            return option === value;
                                          }
                                          return option.id === value.id;
                                        }}
                                        onChange={(event, newValue) => {
                                          const previousData = fields[index].randomInspection || {} as IRandomInspection;
                                          field.onChange(newValue);
                                          update(index, { ...fields[index], randomInspection: newValue ?? {} as IRandomInspection });
                                          handleChange(newValue ?? {} as IRandomInspection, previousData);
                                          calculateTotalAfterBakeWeight();
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="เลขเอกสารตรวจสอบคุณภาพ"
                                            error={!!errors.details?.[index]?.randomInspection}
                                            helperText={errors.details?.[index]?.randomInspection ? "กรุณาเลือกเอกสารตรวจสอบคุณภาพ" : ""}
                                          />
                                        )}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            {
                              !isMobile && (
                                <Grid item md={2} lg={2} alignItems={'center'} display={'flex'}></Grid>
                              )
                            }
                            <Grid item xs={6} sm={6} md={5} lg={5} alignItems={'center'} display={'flex'} >
                              <Typography variant="body1">
                                <span style={{ fontWeight: 'bold' }}>น้ำหนักทั้งหมด: </span> {fields[index].randomInspection && fields[index].randomInspection?.totalRemainingWeight ? thaiFormatNumber(fields[index].randomInspection?.totalRemainingWeight.toString() || '0') || 0 : '0'} กก.
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                        <Grid container spacing={2}>
                          {isMobile && fields.length > 1 ? <>
                            <Grid item xs={4} sm={4} >
                              {fields.length > 1 &&
                                <Button
                                  variant="outlined"
                                  disabled={isDisable}
                                  color="error"
                                  sx={{ width: '100%' }}
                                  type="button"
                                  startIcon={<RemoveCircleIcon />}
                                  onClick={() => {
                                    remove(fields.length - 1);
                                    calculateTotalAfterBakeWeight();
                                  }}
                                >
                                  ลบ
                                </Button>
                              }
                            </Grid>
                            <Grid item xs={8} sm={8} >
                              <Button
                                variant="outlined"
                                sx={{ width: '100%', display: fields.length < randomInspectionList.length ? 'flex' : 'none' }}
                                type="button"
                                disabled={isDisable}
                                startIcon={<AddCircleIcon />}
                                onClick={() => append({
                                  id: "",
                                  randomInspection: {} as IRandomInspection,
                                })}
                              >
                                เพิ่มรายการ
                              </Button>

                            </Grid></> : <Grid item md={12} lg={12}>
                            <Button
                              variant="outlined"
                              sx={{ width: '100%', display: fields.length < randomInspectionList.length ? 'flex' : 'none' }}
                              type="button"
                              disabled={isDisable}
                              startIcon={<AddCircleIcon />}
                              onClick={() => append({
                                id: "",
                                randomInspection: {} as IRandomInspection,
                              })}
                            >
                              เพิ่มรายการ
                            </Button>

                          </Grid>
                          }

                        </Grid>
                      </Grid>
                    </>
                  }
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button component="label" variant="outlined" disabled={isDisable} startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton disabled={isDisable} size="small" color="error" onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    {file.type === 'application/pdf' ? (
                                      <Typography variant="body2">
                                        <a
                                          href={URL.createObjectURL(file)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                          {file.name}
                                        </a>
                                      </Typography>
                                    ) : (
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                        style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                        onClick={() => onImageClick(URL.createObjectURL(file))}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      disabled={isDisable}
                                      color="error" onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}

                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {pdfFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {pdfFiles.map((file: any, index: number) => (
                              <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                                <Grid container>
                                  <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                    <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "inherit", cursor: "pointer" }}
                                    >
                                      {file.originalName}
                                    </a>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} mt={1} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>
                      <Grid item xs={7} sm={7} md={7} lg={7}>
                        <Typography ><b>น้ำหนักรวมทั้งหมด (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'}>
                        <Typography >

                          {thaiFormatNumber(parseFloat(getValues('totalBeforeBakeWeight')?.toString() ?? '0').toFixed(2))}
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'} display={'flex'}>
                        <Typography ><b>น้ำหนักตกเกรดทั้งหมด (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                        <Controller
                          name="issueWeight"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              type="number"
                              disabled={isDisable}
                              error={!!errors.issueWeight}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                calculateTotalAfterBakeWeight();
                              }}
                              helperText={errors.issueWeight ? "กรุณาระบุน้ำหนักตกเกรดทั้งหมด" : ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'}>
                        <Typography ><b>น้ำหนักตรวจเซ็นเซอร์ (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                        <Grid container spacing={2}>
                          {
                            censorInspecWeight.map((item, index: number) => (
                              <>
                                {!isMobile && (
                                  <Grid item xs={2} sm={2} md={2} lg={2} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton
                                      disabled={isDisable}
                                      color="error"
                                      onClick={() => {
                                        const _censorInspecWeight = [...censorInspecWeight];
                                        _censorInspecWeight.splice(index, 1);
                                        setCensorInspecWeight(_censorInspecWeight);
                                      }}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                )}

                                <Grid item xs={12} sm={12} md={10} lg={10} textAlign={'end'} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                  <TextField
                                    fullWidth
                                    type="number"
                                    disabled={isDisable}
                                    value={item.value}
                                    onChange={(e) => {
                                      const _censorInspecWeight = [...censorInspecWeight];
                                      _censorInspecWeight[index].value = parseFloat(e.target.value);
                                      setCensorInspecWeight(_censorInspecWeight);
                                      calculateTotalAfterBakeWeight();
                                    }}
                                    label={`ตู้ที่ ${index + 1}`}
                                  />
                                </Grid>
                              </>
                            ))
                          }
                          {isMobile ? <>
                            <Grid item xs={12} sm={12} >
                              <Button
                                variant="outlined"
                                disabled={isDisable}
                                color="error"
                                sx={{ width: '100%' }}
                                type="button"
                                startIcon={<RemoveCircleIcon />}
                                onClick={() => {
                                  const _censorInspecWeight = [...censorInspecWeight];
                                  _censorInspecWeight.splice(censorInspecWeight.length - 1, 1);
                                  setCensorInspecWeight(_censorInspecWeight);
                                }}

                              >
                                ลบ
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} >
                              <Button
                                variant="outlined"
                                sx={{ width: '100%' }}
                                type="button"
                                disabled={isDisable}
                                startIcon={<AddCircleIcon />}
                                onClick={() => {
                                  setCensorInspecWeight([...censorInspecWeight, { id: uuidv4(), value: 0 }]);
                                }}
                              >
                                เพิ่มรายการ
                              </Button>

                            </Grid></> : <Grid item md={12} lg={12}>
                            <Button
                              variant="outlined"
                              sx={{ width: '100%' }}
                              type="button"
                              disabled={isDisable}
                              startIcon={<AddCircleIcon />}
                              onClick={() => {
                                setCensorInspecWeight([...censorInspecWeight, { id: uuidv4(), value: 0 }]);
                              }}
                            >
                              เพิ่มรายการ
                            </Button>

                          </Grid>
                          }
                        </Grid>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'} display={'flex'}>
                        <Typography ><b>น้ำหนักคัดออกหลังอบ (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                        <Controller
                          name="eliminateWeight"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              type="number"
                              disabled={isDisable}
                              error={!!errors.eliminateWeight}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                calculateTotalAfterBakeWeight();
                              }}
                              helperText={errors.eliminateWeight ? "กรุณาระบุ น้ำหนักคัดออกหลังอบ" : ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={7}>
                        <Typography ><b>น้ำหนักหลังอบทั้งหมด (Kg)</b></Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'}>
                        <Typography >

                          {thaiFormatNumber(parseFloat(getValues('totalAfterBakeWeight')?.toString() ?? '0').toFixed(2))}
                        </Typography>
                      </Grid>
                      {/* {
                        userGroup?.canFinance && (
                          <>
                            <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'} display={'flex'}>
                              <Typography ><b>ราคาอบ (บาท)</b></Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                              <TextField
                                fullWidth
                                type="number"
                                disabled={isDisable}
                                {...register("vhtCost")}
                                error={!!errors.vhtCost}
                                helperText={errors.vhtCost ? "กรุณาระบุ ราคาอบ" : ""}
                                onChange={(e) => calculateVHT(parseFloat(e.target.value), isVat7, isVat3)}
                              />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'} display={'flex'}>
                              <FormControl fullWidth>
                                <FormControlLabel
                                  control={
                                    <Checkbox color="primary"
                                      value={isVat7}
                                      disabled={isDisable}
                                      checked={isVat7 ? true : false}
                                      onChange={(e) => {
                                        setIsVat7(e.target.checked);
                                        const vhtCost = getValues('vhtCost');
                                        calculateVHT(parseFloat(vhtCost ? vhtCost.toString() : "0"), e.target.checked, isVat3);

                                      }

                                      }
                                    />}
                                  label="VAT 7%"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                              {isVat7 ? thaiFormatNumber(parseFloat(getValues('vat7')?.toString() ?? '0').toFixed(2)) : '0.00'}
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'} display={'flex'}>
                              <FormControl fullWidth>
                                <FormControlLabel
                                  control={
                                    <Checkbox color="primary"
                                      value={isVat3}
                                      disabled={isDisable}
                                      checked={isVat3 ? true : false}
                                      onChange={(e) => {
                                        setIsVat3(e.target.checked);
                                        calculateVHT(parseFloat(getValues('vhtCost')?.toString() ?? '0'), isVat7, e.target.checked);
                                      }
                                      }
                                    />}
                                  label="VAT 3%"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                              {isVat3 ? thaiFormatNumber(parseFloat(getValues('vat3')?.toString() ?? '0').toFixed(2)) : '0.00'}
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} alignItems={'center'} display={'flex'}>
                              <Typography ><b>ราคาอบสุทธิ (บาท)</b></Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} textAlign={'end'} justifyContent={'end'} alignItems={'center'} display={'flex'}>
                              {thaiFormatNumber(parseFloat(getValues('totalVht')?.toString() ?? '0').toFixed(2))}
                            </Grid>
                          </>
                        )
                      } */}

                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้ทํารายการ: </b>
                            {props.bake?.createBy?.firstName} {props.bake?.createBy?.lastName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} alignItems={"center"}>
                          <Typography>
                            <b>ผู้อนุมัติ/ปฏิเสธ: </b>
                            {props.bake?.approveBy?.firstName} {props.bake?.approveBy?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} justifyContent={"end"}>
                    <Stack spacing={2} direction="row">
                      {
                        isFromStatus ? (
                          <>
                            {
                              userGroup?.canApprove && props.bake.status === "waiting" && (
                                <>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={onReject}
                                  >
                                    ปฏิเสธ
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    onClick={onApprove}
                                    style={{ marginLeft: '8px' }}
                                  >
                                    อนุมัติ
                                  </Button>
                                </>
                              )
                            }
                          </>
                        ) :
                          <>
                            {
                              <> <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => navigate("/process-bake")}
                              >
                                ยกเลิก
                              </Button>
                                {
                                  (props.permission.create || (props.bake.id && props.permission.edit)) && props.bake.status !== 'approved' ?
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      onClick={handleSave}
                                    >
                                      บันทึก
                                    </Button>
                                    : <></>
                                }
                              </>
                            }
                          </>
                      }

                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onSubmit();
          }
        }}
      />
      <ImagePreview
        open={openImage}
        imageSrc={selectedImage}
        onClose={() => setOpenImage(false)}
      />
    </Box>
  );
};

export default BakeCreateView;