import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import invoiceService, { IInvoice } from "../Services/InvoiceService";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import InvoiceCreateView from "../Views/InvoiceCreateView";
import clearState from "../../../utils/resetState";

const InvoiceCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permission, setPermission] = useState<IUserDetail>();


  useEffect(() => {
    if (id) {
      getOne(id);
    }
    checkPermissionActionButton();
    return () => {
      clearState(invoiceService, ["invoice"]);
    };
  }, [id]);

  const getOne = async (id: string) => {
    try {
      await invoiceService.getOne(id);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const onCreate = async (data: IInvoice) => {
    try {
      const result = await invoiceService.create(data);
      if (result) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate(`/process-invoice-edit/${result?.id}`);
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while getting the products:", error);
    }
  };

  const onUpdate = async (data: IInvoice) => {
    try {
      await invoiceService.update(id ?? '', data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-invoice-edit/${data.id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while getting the products:", error);
    }
  };

  const onDelete = async () => {
    try {
      await invoiceService.delete(id ?? '');
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-invoice-list`);
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while getting the products:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-invoice");
    if (result) {
      setPermission(result);
    }
  };

  return <InvoiceCreateView
    invoice={invoiceService.invoiceData ?? {} as IInvoice}
    permission={permission ?? {} as IUserDetail}
    onCreate={onCreate}
    onUpdate={onUpdate}
    onDelete={onDelete}
  />;
});

export default InvoiceCreateController;