import { FC } from "react";
import { IReportCriteria, ISummaryReport, ISummaryReports } from "../Services/ReportService";
import ReportListView from "../../../components/ReportListView";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";


interface SummaryReportListViewProps {
  isLoading: boolean;
  summaryReportData: ISummaryReports;
  getSummaryReportData: (criteria: IReportCriteria) => void;

}
const SummaryReportListView: FC<SummaryReportListViewProps> = (props) => {
  const summaryReportColumns = [
    { label: 'Invoice No.', render: (row: ISummaryReport) => row.invoiceNo ?? "" },
    { label: 'ชื่อโปรเจกต์', render: (row: ISummaryReport) => row.projectName ?? "" },
    {
      label: 'จำนวนส่งออก', render: (row: ISummaryReport) => (
        <strong>{row.totalExport ? thaiFormatNumber(row.totalExport.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ราคาขาย', render: (row: ISummaryReport) => (
        <strong>{row.totalSales ? thaiFormatNumber(row.totalSales.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ต้นทุนมะม่วง', render: (row: ISummaryReport) => (
        <strong>{row.totalCostMango ? thaiFormatNumber(row.totalCostMango.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ต้นทุนอบ', render: (row: ISummaryReport) => (
        <strong>{row.totalCostBake ? thaiFormatNumber(row.totalCostBake.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ต้นทุนอุปกรณ์แพ็ค', render: (row: ISummaryReport) => (
        <strong>{row.totalCostPack ? thaiFormatNumber(row.totalCostPack.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ต้นทุน shipping', render: (row: ISummaryReport) => (
        <strong>{row.totalCostShipping ? thaiFormatNumber(row.totalCostShipping.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ค่าแรง', render: (row: ISummaryReport) => (
        <strong>{row.totalWage ? thaiFormatNumber(row.totalWage.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'ต้นทุนทั้งหมด', render: (row: ISummaryReport) => (
        <strong>{row.totalCost ? thaiFormatNumber(row.totalCost.toString()) : "0.00"}</strong>
      )
    },
    {
      label: 'คงเหลือ', render: (row: ISummaryReport) => (
        <strong>{row.totalRemaining ? thaiFormatNumber(row.totalRemaining.toString()) : "0.00"}</strong>
      )
    },

  ];

  const totalValues = [
    { label: "ต้นทุน", value: props.summaryReportData?.totalValue?.totalCost, percentage: props.summaryReportData?.totalValue?.costPercentChange },
    { label: "ยอดขาย", value: props.summaryReportData?.totalValue?.totalSales, percentage: props.summaryReportData?.totalValue?.salesPercentChange },
    { label: "กำไร", value: props.summaryReportData?.totalValue?.totalProfit, percentage: props.summaryReportData?.totalValue?.profitPercentChange },
    { label: "ยอดสูญเสีย", value: props.summaryReportData?.totalValue?.totalLoss, percentage: props.summaryReportData?.totalValue?.lossPercentChange },
  ];


  return (
    <>
      <ReportListView
        isLoading={props.isLoading}
        reportData={props.summaryReportData}
        getReportData={props.getSummaryReportData}
        reportTitle="รายงานสรุป"
        totalValues={totalValues}
        reportColumns={summaryReportColumns}

      />
    </>

  );
};

export default SummaryReportListView;
